import fs from 'fs'
import path from 'path'

import { FaqItemType } from '@/types/common.type'

export const openInNewTab = (url: any) => {
  window.open(url, '_blank', 'noopener,noreferrer')
}

export const loadFAQ = (fileName: string) => {
  const filePath = path.join(process.cwd(), fileName)
  const file = fs.readFileSync(filePath, 'utf-8')
  const faqItems: FaqItemType[] = JSON.parse(file)
  return faqItems
}
