export enum Sort {
  Latest = 'SORT::LATEST',
  Oldest = 'SORT::OLDEST',
}

export type GetAllByAdminDto = {
  sort: Sort
  limit?: number
  offset?: number
  search?: string
}
