import { marketplaceApi } from '../base-axios/marketplaceApi'
import { GetAllByAdminDto } from './type'
import { IChain } from '@/types/chain.type'

const CONTROLLER = 'chain'

class Chain {
  async getAllChain({
    offset,
    limit,
    search,
    sort,
  }: GetAllByAdminDto): Promise<IChain[]> {
    return marketplaceApi
      .get(CONTROLLER, { params: { offset, limit, search, sort } })
      .then((res) => res.data)
  }
}

export const chainService = new Chain()
