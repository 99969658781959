import React from 'react'

export const CopperCoinIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_17211_11014)">
        <path
          d="M7.99992 15.1663C4.31792 15.1663 1.33325 12.1817 1.33325 8.49967C1.33325 4.81767 4.31792 1.83301 7.99992 1.83301C11.6819 1.83301 14.6666 4.81767 14.6666 8.49967C14.6666 12.1817 11.6819 15.1663 7.99992 15.1663ZM7.99992 5.67101L5.17125 8.49967L7.99992 11.3283L10.8286 8.49967L7.99992 5.67101Z"
          fill="#0FDBD1"
        />
      </g>
      <defs>
        <clipPath id="clip0_17211_11014">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
