import React, { PropsWithChildren, useEffect } from 'react'
import { useRefreshToken } from '@/hooks/useRefreshToken'

function UserProvider({ children }: PropsWithChildren) {
  const { refreshToken } = useRefreshToken()

  useEffect(() => {
    refreshToken()
  }, [refreshToken])

  return <>{children}</>
}

export default UserProvider
