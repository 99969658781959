export const SortIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.75 7C22.75 7.19891 22.671 7.38968 22.5303 7.53033C22.3897 7.67098 22.1989 7.75 22 7.75H2C1.80109 7.75 1.61032 7.67098 1.46967 7.53033C1.32902 7.38968 1.25 7.19891 1.25 7C1.25 6.80109 1.32902 6.61032 1.46967 6.46967C1.61032 6.32902 1.80109 6.25 2 6.25H22C22.1989 6.25 22.3897 6.32902 22.5303 6.46967C22.671 6.61032 22.75 6.80109 22.75 7ZM19.75 12C19.75 12.1989 19.671 12.3897 19.5303 12.5303C19.3897 12.671 19.1989 12.75 19 12.75H5C4.80109 12.75 4.61032 12.671 4.46967 12.5303C4.32902 12.3897 4.25 12.1989 4.25 12C4.25 11.8011 4.32902 11.6103 4.46967 11.4697C4.61032 11.329 4.80109 11.25 5 11.25H19C19.1989 11.25 19.3897 11.329 19.5303 11.4697C19.671 11.6103 19.75 11.8011 19.75 12ZM16.75 17C16.75 17.1989 16.671 17.3897 16.5303 17.5303C16.3897 17.671 16.1989 17.75 16 17.75H8C7.80109 17.75 7.61032 17.671 7.46967 17.5303C7.32902 17.3897 7.25 17.1989 7.25 17C7.25 16.8011 7.32902 16.6103 7.46967 16.4697C7.61032 16.329 7.80109 16.25 8 16.25H16C16.1989 16.25 16.3897 16.329 16.5303 16.4697C16.671 16.6103 16.75 16.8011 16.75 17Z"
      fill="#EBEBEB"
    />
  </svg>
)
