const ACCESS_TOKEN = 'accessToken'
export const getAccessToken = () => {
  const token =
    typeof window !== 'undefined'
      ? localStorage?.getItem(ACCESS_TOKEN) || ''
      : ''
  return token
}

export const setAccessToken = (accessToken: string) => {
  return localStorage.setItem(ACCESS_TOKEN, accessToken)
}

export const removeAccessToken = () => {
  return localStorage.removeItem(ACCESS_TOKEN)
}

export const getAuthorizationHeader = () => {
  const accessToken = getAccessToken()
  return { Authorization: `Bearer ${accessToken}` }
}
