import { InjectedConnectorOptions } from '@wagmi/core'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { Chain } from 'wagmi'

export class Ancient8Connector extends InjectedConnector {
  readonly id = 'ancient8'
  readonly ready = typeof window != 'undefined' && window.ancient8

  provider?: Window['ancient8']

  constructor({
    chains,
    options,
  }: { chains?: Chain[]; options?: InjectedConnectorOptions } = {}) {
    super({ chains, options: { name: 'Ancient8', ...options } })
  }

  async getProvider() {
    if (typeof window !== 'undefined') {
      return window.ancient8
    }
    return
  }
}
