export const CommunityIcon = () => (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 15.9711C15 14.3141 12.761 12.9711 10 12.9711C7.239 12.9711 5 14.3141 5 15.9711M19 12.9711C19 11.7411 17.766 10.6841 16 10.2211M1 12.9711C1 11.7411 2.234 10.6841 4 10.2211M16 6.2071C16.2963 5.94519 16.5378 5.62729 16.7108 5.27169C16.8838 4.9161 16.9847 4.52981 17.0079 4.13506C17.031 3.7403 16.9759 3.34487 16.8456 2.97149C16.7154 2.59812 16.5127 2.25417 16.249 1.95944C15.9854 1.6647 15.6661 1.425 15.3095 1.2541C14.9529 1.08321 14.5661 0.984495 14.1712 0.963646C13.7763 0.942796 13.3812 1.00022 13.0086 1.13261C12.636 1.26501 12.2932 1.46976 12 1.7351M4 6.2071C3.4123 5.67524 3.05896 4.93242 3.01717 4.1409C2.97538 3.34937 3.24853 2.57347 3.77696 1.98269C4.30539 1.3919 5.04614 1.03425 5.83741 0.987863C6.62868 0.941472 7.40616 1.21011 8 1.7351M10 9.9711C9.60603 9.9711 9.21593 9.8935 8.85195 9.74274C8.48797 9.59197 8.15726 9.37099 7.87868 9.09242C7.6001 8.81384 7.37913 8.48312 7.22836 8.11915C7.0776 7.75517 7 7.36506 7 6.9711C7 6.57713 7.0776 6.18702 7.22836 5.82305C7.37913 5.45907 7.6001 5.12835 7.87868 4.84978C8.15726 4.5712 8.48797 4.35022 8.85195 4.19946C9.21593 4.04869 9.60603 3.9711 10 3.9711C10.7956 3.9711 11.5587 4.28717 12.1213 4.84978C12.6839 5.41239 13 6.17545 13 6.9711C13 7.76675 12.6839 8.52981 12.1213 9.09242C11.5587 9.65503 10.7956 9.9711 10 9.9711Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
)
