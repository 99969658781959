export const LiveIcon = ({ color = '#1EAF6A' }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10.5 11.8242C10.5 12.6526 11.1716 13.3242 12 13.3242C12.8284 13.3242 13.5 12.6526 13.5 11.8242C13.5 10.9958 12.8284 10.3242 12 10.3242C11.1716 10.3242 10.5 10.9958 10.5 11.8242Z"
        fill={color}
      />
      <path
        d="M16.3256 7.67578L15.2658 8.73628C16.0573 9.56744 16.4993 10.6708 16.5006 11.8186C16.5018 12.9663 16.0622 14.0706 15.2726 14.9035L16.3323 15.9633C17.4036 14.8494 18.0014 13.3635 18 11.818C17.9986 10.2725 17.3989 8.78777 16.3256 7.67578Z"
        fill={color}
      />
      <path
        d="M7.67443 7.67578L8.73418 8.73628C7.94271 9.56744 7.5007 10.6708 7.49944 11.8186C7.49818 12.9663 7.93778 14.0706 8.72743 14.9035L7.66768 15.9633C6.59638 14.8494 5.9986 13.3635 6 11.818C6.0014 10.2725 6.60111 8.78777 7.67443 7.67578Z"
        fill={color}
      />
      <path
        d="M19.5054 4.5L18.4457 5.5605C20.0821 7.23445 20.999 9.48202 21.0004 11.823C21.0018 14.1639 20.0876 16.4126 18.4531 18.0885L19.5129 19.1483C21.429 17.1914 22.5014 14.5613 22.5 11.8226C22.4986 9.08388 21.4235 6.45489 19.5054 4.5Z"
        fill={color}
      />
      <path
        d="M4.4946 4.5L5.55435 5.5605C3.91791 7.23445 3.00104 9.48202 2.99964 11.823C2.99824 14.1639 3.91242 16.4126 5.54685 18.0885L4.4871 19.1483C2.57104 17.1914 1.4986 14.5613 1.5 11.8226C1.5014 9.08388 2.57654 6.45489 4.4946 4.5Z"
        fill={color}
      />
    </svg>
  )
}
