export const AccessCriteriaIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="12"
    viewBox="0 0 6 12"
    fill="none"
  >
    <path
      d="M0 0.909058L4.95809 5.24739C5.41342 5.6458 5.41342 6.35413 4.95809 6.75254L0 11.0909V0.909058Z"
      fill="#2EF2FF"
    />
  </svg>
)
