import { useState } from 'react'
import { useInterval, useMap, useMount } from 'react-use'
import clsx from 'clsx'

import { Button, Image, Input, Row, Typography } from 'antd'
import CloseIcon from './CloseIcon'

import { useUserStore } from '@/stores/user.store'
import { useCustomerStore } from '@/stores/customer.store'
import { useLoginModalValue } from '.'

import {
  loginEmailOtp,
  signupEmailOtp,
  validateEmail,
} from '@/services/auth/emailAuth'
import { AuthType } from '@/services/auth/authType'
import { notify } from '@/providers/notify.provider'

import { ModalType } from '@/types/common.type'

import HeaderIcon from '@/assets/images/logo/brand.svg'
import A8HeaderIcon from '@/assets/images/logo/a8-logo.svg'
import Email from '@/assets/images/login-modal/email.svg'
import EmailA8 from '@/assets/images/login-modal/email-a8.png'

import './index.scss'

interface IProps {
  emailAddress: string
  modalType?: ModalType
}

export default function EmailOTP({
  emailAddress,
  modalType = ModalType.Space3,
}: IProps) {
  const [otp, setOtp] = useState('')
  const [confirming, setConfirming] = useState<boolean>(false)
  const { login, sendEmailOTP } = useUserStore()
  const { getCustomers } = useCustomerStore()
  const [, setLoginModalValue] = useLoginModalValue()

  const [, { set, get, reset }] = useMap({
    count: 60,
    isResending: false,
  })

  useInterval(
    () => {
      const count = get('count')
      if (count === 0) {
        reset()
      } else {
        set('count', count - 1)
      }
    },
    get('isResending') ? 1000 : null,
  )

  const handleResendCode = () => {
    if (get('isResending')) return
    sendEmailOTP(emailAddress)
    setOtp('')
    set('isResending', true)
  }

  useMount(() => sendEmailOTP(emailAddress))

  const handleConfirm = async () => {
    setConfirming(true)
    const existed = await validateEmail(emailAddress)
    const validate = existed ? loginEmailOtp : signupEmailOtp
    try {
      const { accessToken } = await validate({
        type: AuthType.EmailOTP,
        token: otp.trim(),
        email: emailAddress,
      })
      const userProfile = await login(accessToken)
      if (userProfile?._id) getCustomers()
      setLoginModalValue(false)
    } catch (err) {
      return notify.error({ message: 'Invalid OTP Code' })
    } finally {
      setConfirming(false)
    }
  }

  if (modalType === ModalType.Ancient8) {
    return (
      <div className="a8-login-modal-email-otp">
        <CloseIcon className="hover:text-a8-primary" />
        <Image
          preview={false}
          src={A8HeaderIcon.src}
          alt="logo-login"
          className="brand-logo"
        />
        <Row className="verify-email !text-[#E7E7E7]">Verify Your Email</Row>
        <Row gutter={[0, 32]} justify={'center'} style={{ padding: '16px 0' }}>
          <Image preview={false} src={EmailA8.src} alt="logo-login" />
          <Row justify="center" gutter={[0, 4]} style={{ padding: 8 }}>
            <Typography.Text>
              Please enter the 6 digit code sent to
            </Typography.Text>
            <Typography.Text type="success" className="email-address">
              {emailAddress}
            </Typography.Text>
          </Row>
          <Row style={{ width: '100%' }}>
            <Input
              onChange={(e) => setOtp(e.target.value)}
              value={otp}
              className="email-input"
              placeholder="Please enter OTP number"
            />
          </Row>
        </Row>
        <Row gutter={[0, 8]} style={{ width: '100%' }}>
          <Button
            onClick={handleResendCode}
            block
            type="link"
            style={{ height: 36 }}
          >
            {get('isResending') ? `${get('count')} s` : 'Resend'}
          </Button>
          <Button
            disabled={otp.length !== 6 || confirming}
            block
            loading={confirming}
            onClick={handleConfirm}
            className={clsx(
              'rounded-lg bg-a8-primary !text-b2 font-bold hover:!bg-a8-primary/90 h-11',
              'disabled:!bg-[#1C2023] disabled:!border-transparent disabled:!text-secondary',
            )}
          >
            Confirm
          </Button>
        </Row>
      </div>
    )
  }

  return (
    <div className="a8-login-modal-email-otp">
      <CloseIcon />
      <Image
        preview={false}
        src={HeaderIcon.src}
        alt="logo-login"
        className="brand-logo"
      />
      <Row className="verify-email">Verify Your Email</Row>
      <Row gutter={[0, 32]} justify={'center'} style={{ padding: '16px 0' }}>
        <Image preview={false} src={Email.src} alt="logo-login" />
        <Row justify={'center'} gutter={[0, 4]} style={{ padding: 8 }}>
          <Typography.Text>
            Please enter the 6 digit code sent to
          </Typography.Text>
          <Typography.Text type="success" className="email-address">
            {emailAddress}
          </Typography.Text>
        </Row>
        <Row style={{ width: '100%' }}>
          <Input
            onChange={(e) => setOtp(e.target.value)}
            value={otp}
            className="email-input"
            placeholder="Please enter OTP number"
          />
        </Row>
      </Row>
      <Row gutter={[0, 8]} style={{ width: '100%' }}>
        <Button
          onClick={handleResendCode}
          block
          type="link"
          style={{ height: 36 }}
        >
          {get('isResending') ? `${get('count')} s` : 'Resend'}
        </Button>
        <Button
          disabled={otp.length !== 6 || confirming}
          block
          type="primary"
          onClick={handleConfirm}
          style={{ height: 44 }}
        >
          Confirm
        </Button>
      </Row>
    </div>
  )
}
