import { useCallback, useEffect, useState } from 'react'
import jwt from 'jsonwebtoken'
import dayjs from '@/libs/utc-date'
import { useDisconnect as useWagmiDisconnect } from 'wagmi'

import { fetchRefreshToken } from '@/services/auth/refreshToken'

import { useUserStore } from '@/stores/user.store'
import { useMainStore } from '@/stores/main.store'

import { ChainID } from '@/constants'

export const useRefreshToken = () => {
  const [accessToken, setAccessToken] = useState<string | null>(null)

  const logout = useUserStore((state) => state.logout)
  const onSwitchWallet = useMainStore((state) => state.onSwitchWallet)
  const { disconnect: wagmiDisconnect } = useWagmiDisconnect()

  const handleLogout = useCallback(() => {
    wagmiDisconnect()
    logout()
    onSwitchWallet({ chain: ChainID.Default, walletType: '', address: '' })
  }, [logout, onSwitchWallet, wagmiDisconnect])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setAccessToken(localStorage.getItem('accessToken'))
    }
  }, [])

  const refreshToken = useCallback(async () => {
    const tokenDecoded: any = jwt.decode(accessToken as string)
    const expiredTime = tokenDecoded?.exp ?? 0
    const dayExp = dayjs(expiredTime * 1_000)

    const diff = dayExp.diff(dayjs().utc(), 'seconds')

    const isLoggedIn = !!accessToken?.length

    // Prevent catch event when localStorage not ready
    if (accessToken === null) return

    // Do nothing if user have not logged in yet
    if (!isLoggedIn) return

    // Do nothing if expired time is more than 24 hours
    if (diff >= 24 * 60 * 60) return

    // Logout if expired time is less than 0
    if (isLoggedIn && diff < 0) return handleLogout()

    return fetchRefreshToken()
      .then((data) => {
        const accessToken = data.data.accessToken
        const userLocal = localStorage.getItem('user')
        const user = JSON.parse(userLocal ?? '')
        const newUser = {
          ...user,
          state: {
            ...user?.state,
            accessToken,
          },
        }
        localStorage.setItem('user', JSON.stringify(newUser))
        localStorage.setItem('accessToken', accessToken)
      })
      .catch(() => {
        handleLogout()
      })
  }, [accessToken, handleLogout])

  return { refreshToken }
}
