import { Chain } from '@wagmi/core'

export const ancient8Testnet = {
  id: 2863311531,
  name: 'Ancient8 Testnet',
  network: 'ancient8-testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc-testnet.ancient8.gg'] },
    default: { http: ['https://rpc-testnet.ancient8.gg'] },
  },
  blockExplorers: {
    etherscan: {
      name: 'Ancient8Explorer',
      url: 'https://testnet.a8scan.io',
    },
    default: {
      name: 'Ancient8Explorer',
      url: 'https://testnet.a8scan.io',
    },
  },
  contracts: {},
  testnet: true,
} as const satisfies Chain
