import { Chain } from '@wagmi/core'

export const etherlink = {
  id: 42793,
  name: 'Etherlink Mainnet',
  network: 'etherlink',
  nativeCurrency: {
    decimals: 18,
    name: 'XTZ',
    symbol: 'XTZ',
  },
  rpcUrls: {
    public: { http: ['https://node.mainnet.etherlink.com'] },
    default: { http: ['https://node.mainnet.etherlink.com'] },
  },
  blockExplorers: {
    etherscan: {
      name: 'Etherlink Explorer',
      url: 'https://explorer.etherlink.com',
    },
    default: {
      name: 'Etherlink Explorer',
      url: 'https://explorer.etherlink.com',
    },
  },
  contracts: {},
} as const satisfies Chain
