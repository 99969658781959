import { OauthConf } from '@/services/auth/configs/oauth.config'
import { encodeState } from '@/services/oauth'

export enum SocialAccounts {
  Email = 'Email',
  Twitter = 'X',
  Facebook = 'Facebook',
  Twitch = 'Twitch',
  Discord = 'Discord',
  GOOGLE = 'Google',
}

export enum ActionOauth {
  Login = 'Login',
  Connect = 'Connect',
}

export const oauthUrlMap = (
  oauth: OauthConf,
  type: string,
  pathname: string,
  redirectUri?: string,
  actionOauth?: ActionOauth,
) => {
  return {
    [SocialAccounts.Twitter]: `${
      oauth.X.authorizationEndpoint
    }?response_type=code&client_id=${oauth.X.clientId}&state=${encodeState({
      destination: pathname,
      actionOauth: actionOauth ?? ActionOauth.Login,
      type: SocialAccounts.Twitter,
    })}&redirect_uri=${encodeURIComponent(
      type === 'login'
        ? oauth.X.loginCallbackUri()
        : oauth.X.connectCallbackUri(redirectUri),
    )}&scope=tweet.read%20users.read%20offline.access&code_challenge=challenge&code_challenge_method=plain`,

    [SocialAccounts.GOOGLE]: `${oauth.Google.authorizationEndpoint}?client_id=${
      oauth.Google.clientId
    }&include_granted_scopes=true&scope=https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email&state=${encodeState(
      {
        destination: pathname,
        actionOauth: actionOauth ?? ActionOauth.Login,
        type: SocialAccounts.GOOGLE,
      },
    )}&redirect_uri=${encodeURIComponent(
      type === 'login'
        ? oauth.Google.loginCallbackUri()
        : oauth.Google.connectCallbackUri(redirectUri),
    )}&response_type=code`,

    [SocialAccounts.Facebook]: `${
      oauth.Facebook.authorizationEndpoint
    }?client_id=${oauth.Facebook.clientId}&state=${encodeState({
      destination: pathname,
      actionOauth: actionOauth ?? ActionOauth.Login,
      type: SocialAccounts.Facebook,
    })}&redirect_uri=${encodeURIComponent(
      type === 'login'
        ? oauth.Facebook.loginCallbackUri()
        : oauth.Facebook.connectCallbackUri(redirectUri),
    )}`,

    [SocialAccounts.Twitch]: `${
      oauth.Twitch.authorizationEndpoint
    }?response_type=code&client_id=${
      oauth.Twitch.clientId
    }&scope=channel%3Amanage%3Apolls+channel%3Aread%3Apolls&state=${encodeState(
      {
        destination: pathname,
        actionOauth: actionOauth ?? ActionOauth.Login,
        type: SocialAccounts.Twitch,
      },
    )}&redirect_uri=${encodeURIComponent(
      type === 'login'
        ? oauth.Twitch.loginCallbackUri()
        : oauth.Twitch.connectCallbackUri(redirectUri),
    )}`,

    [SocialAccounts.Discord]: `${
      oauth.Discord.authorizationEndpoint
    }?response_type=code&client_id=${
      oauth.Discord.clientId
    }&scope=identify&state=${encodeState({
      destination: pathname,
      actionOauth: actionOauth ?? ActionOauth.Login,
      type: SocialAccounts.Discord,
    })}&redirect_uri=${encodeURIComponent(
      type === 'login'
        ? oauth.Discord.loginCallbackUri()
        : oauth.Discord.connectCallbackUri(redirectUri),
    )}&prompt=consent`,
  }
}
