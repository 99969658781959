import { SocialAccounts } from '@/constants/oauth'

interface IAccountInfo {
  authorizationEndpoint: string
  clientId: string
  loginCallbackUri: () => string
  connectCallbackUri: (redirectUri?: string) => string
}

export type OauthConf = {
  [SocialAccounts.Discord]: IAccountInfo
  [SocialAccounts.Twitter]: IAccountInfo
  [SocialAccounts.Facebook]: IAccountInfo
  [SocialAccounts.Twitch]: IAccountInfo
  [SocialAccounts.GOOGLE]: IAccountInfo
}

const connectCallbackUri = (redirectUri?: string) => {
  const uri = redirectUri ?? '/profile'
  return location.origin + uri
}
const loginCallbackUri = () => location.origin + '/oauth'

const config: OauthConf = {
  [SocialAccounts.Discord]: {
    authorizationEndpoint: process.env
      .NEXT_PUBLIC_DISCORD_OAUTH_ENDPOINT as string,
    clientId: process.env.NEXT_PUBLIC_A8_DISCORD_CLIENT_ID as string,
    loginCallbackUri,
    connectCallbackUri,
  },
  [SocialAccounts.Twitter]: {
    authorizationEndpoint: process.env
      .NEXT_PUBLIC_TWITTER_OAUTH_ENDPOINT as string,
    clientId: process.env.NEXT_PUBLIC_A8_TWITTER_CLIENT_ID as string,
    loginCallbackUri,
    connectCallbackUri,
  },
  [SocialAccounts.Facebook]: {
    authorizationEndpoint: process.env
      .NEXT_PUBLIC_FACEBOOK_OAUTH_ENDPOINT as string,
    clientId: process.env.NEXT_PUBLIC_A8_FACEBOOK_CLIENT_ID as string,
    loginCallbackUri,
    connectCallbackUri,
  },
  [SocialAccounts.Twitch]: {
    authorizationEndpoint: process.env
      .NEXT_PUBLIC_TWITCH_OAUTH_ENDPOINT as string,
    clientId: process.env.NEXT_PUBLIC_A8_TWITCH_CLIENT_ID as string,
    loginCallbackUri,
    connectCallbackUri,
  },
  [SocialAccounts.GOOGLE]: {
    authorizationEndpoint: process.env
      .NEXT_PUBLIC_GOOGLE_OAUTH_ENDPOINT as string,
    clientId: process.env.NEXT_PUBLIC_A8_GOOGLE_CLIENT_ID as string,
    loginCallbackUri,
    connectCallbackUri,
  },
}

/**
 * Module exports
 */
export default config
