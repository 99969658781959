export const DoubleUpArrow = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_9780_222698)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.79303 18.0221L12 10.2621L19.207 18.0211L18.474 18.7011L12 11.7321L5.52603 18.7031L4.79303 18.0221ZM18.473 13.2021L12 6.23212L5.52603 13.2041L4.79303 12.5241L12 4.76012L19.207 12.5221L18.473 13.2021Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_9780_222698">
        <rect
          width="15"
          height="15"
          fill="white"
          transform="translate(19.5 19.5) rotate(-180)"
        />
      </clipPath>
    </defs>
  </svg>
)
