export const MandatoryIcon = () => (
  <svg
    width={25}
    height={20}
    viewBox="0 0 25 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.395 17.915V4.475H4.215V5.45H2.58V16.94H4.215V17.915H1.395Z"
      fill="#FF7300"
    />
    <path
      d="M12.5 5V15"
      stroke="#FF7300"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8213 6.25L8.75 13.3213"
      stroke="#FF7300"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 10H7.5"
      stroke="#FF7300"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8213 13.3213L8.75 6.25"
      stroke="#FF7300"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.045 4.475V17.915H20.225V16.94H21.86V5.45H20.225V4.475H23.045Z"
      fill="#FF7300"
    />
  </svg>
)
