export function encodeState(state: any): string {
  return Buffer.from(JSON.stringify(state)).toString('base64')
}

export function decodeState<T = any>(
  stateString: string | null | undefined,
): T {
  if (!stateString) return {} as T
  return JSON.parse(Buffer.from(stateString, 'base64').toString())
}
