import React from 'react'
import clsx from 'clsx'

import { Modal } from 'antd'

import LoginModalContent from './LoginModalContent'

import useIsMobile from '@/hooks/useIsMobile'

import { ModalType } from '@/types/common.type'

type LoginModalProps = {
  isModalOpen: boolean
  onCloseModal: () => void
  modalType?: ModalType
}

const LoginModal = ({
  isModalOpen,
  onCloseModal,
  modalType = ModalType.Space3,
}: LoginModalProps) => {
  const isMobile = useIsMobile()

  return (
    <Modal
      className={clsx(
        '!w-[400px] !rounded-xl overflow-hidden [&>div.space-3-modal-content]:p-0',
        isMobile && 'top-8',
      )}
      wrapClassName="login-modal-provider"
      footer={null}
      closable={false}
      destroyOnClose
      open={isModalOpen}
    >
      <LoginModalContent closeModal={onCloseModal} modalType={modalType} />
    </Modal>
  )
}

export default LoginModal
