export const ElectionWalletIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M22.8525 11.9265H21.7125V8.49902H20.5725V11.9265H18.285V13.074H17.145V15.354H18.285V16.5015H20.5725V21.069H21.7125V16.5015H22.8525V15.354H24V13.074H22.8525V11.9265ZM20.5725 14.214H19.425V13.074H20.5725V14.214Z"
      fill="#BCFF50"
    />
    <path d="M20.5725 21.0684H19.425V22.2159H20.5725V21.0684Z" fill="#BCFF50" />
    <path d="M20.5725 7.3584H19.425V8.4984H20.5725V7.3584Z" fill="#BCFF50" />
    <path d="M19.425 22.2158H2.28748V23.3558H19.425V22.2158Z" fill="#BCFF50" />
    <path d="M17.145 2.7832H14.8575V3.9307H17.145V2.7832Z" fill="#BCFF50" />
    <path
      d="M14.8574 3.93066H12.5699V5.07066H14.8574V3.93066Z"
      fill="#BCFF50"
    />
    <path d="M12.5701 2.7832H11.4301V3.9307H12.5701V2.7832Z" fill="#BCFF50" />
    <path d="M11.43 1.64355H9.14246V2.78355H11.43V1.64355Z" fill="#BCFF50" />
    <path d="M9.14248 2.7832H6.85498V3.9307H9.14248V2.7832Z" fill="#BCFF50" />
    <path d="M6.85501 3.93066H4.5675V5.07066H6.85501V3.93066Z" fill="#BCFF50" />
    <path d="M5.715 8.49902H4.5675V21.069H5.715V8.49902Z" fill="#BCFF50" />
    <path
      d="M19.425 7.35816V6.21066H18.285V3.93066H17.145V6.21066H12.57V5.07066H11.43V6.21066H4.56748V5.07066H3.42748V6.21066H2.28748V7.35816H19.425Z"
      fill="#BCFF50"
    />
    <path
      d="M2.28751 21.0684H1.14001V22.2159H2.28751V21.0684Z"
      fill="#BCFF50"
    />
    <path d="M2.28751 7.3584H1.14001V8.4984H2.28751V7.3584Z" fill="#BCFF50" />
    <path d="M1.14 8.49902H0V21.069H1.14V8.49902Z" fill="#BCFF50" />
  </svg>
)
