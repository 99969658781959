import { Modal } from 'antd'

import DisclaimerA8ModalContent from './DisclaimerA8ModalContent'

import './index.scss'

type DisclaimerA8ModalProps = {
  isModalOpen: boolean
  onCloseModal: () => void
}

const DisclaimerA8Modal = ({ isModalOpen }: DisclaimerA8ModalProps) => {
  return (
    <Modal
      className="disclaimer-a8-container rounded-2xl !p-0 bg-transparent"
      footer={null}
      closable={false}
      destroyOnClose
      open={isModalOpen}
      width={560}
    >
      <DisclaimerA8ModalContent />
    </Modal>
  )
}

export default DisclaimerA8Modal
