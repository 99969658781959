export const ElectionHouseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
  >
    <path
      d="M15.75 15.4863V14.7363H15V7.98633H13.5V14.7363H12V7.98633H10.5V14.7363H7.5V7.98633H6V14.7363H4.5V7.98633H3V14.7363H2.25V15.4863H0.75V16.9863H1.5V17.7363H16.5V16.9863H17.25V15.4863H15.75ZM15.75 16.9863H2.25V16.2363H3V15.4863H15V16.2363H15.75V16.9863Z"
      fill="white"
    />
    <path
      d="M16.5 5.73633V6.48633H15.75V7.23633H2.25V6.48633H1.5V5.73633H3V6.48633H15V5.73633H16.5Z"
      fill="white"
    />
    <path
      d="M17.25 4.23633V5.73633H16.5V4.98633H14.25V4.23633H12.75V3.48633H11.25V2.73633H9.75V1.98633H8.25V2.73633H6.75V3.48633H5.25V4.23633H3.75V4.98633H1.5V5.73633H0.75V4.23633H3V3.48633H4.5V2.73633H6V1.98633H7.5V1.23633H10.5V1.98633H12V2.73633H13.5V3.48633H15V4.23633H17.25Z"
      fill="white"
    />
  </svg>
)
