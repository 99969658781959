import { Chain } from '@wagmi/core'

export const ancient8CelestiaTestnet = {
  id: 28122024,
  name: 'Ancient8 Testnet V2',
  network: 'ancient8-celestia-testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpcv2-testnet.ancient8.gg'] },
    default: { http: ['https://rpcv2-testnet.ancient8.gg'] },
  },
  blockExplorers: {
    etherscan: {
      name: 'Ancient8CelestiaExplorer',
      url: 'https://scanv2-testnet.ancient8.gg',
    },
    default: {
      name: 'Ancient8CelestiaExplorer',
      url: 'https://scanv2-testnet.ancient8.gg',
    },
  },
  contracts: {},
  testnet: true,
} as const satisfies Chain
