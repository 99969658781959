import { ancient8Api } from '@/services/base-axios/ancient8Api'
import { missionApi } from '@/services/base-axios/missionApi'

import { CHAIN_AUTH_MAP, ChainID } from '@/constants'

import type { UserProfile } from '@/stores/user.store'
import type { IA8IdProfile } from '@/services/auth/authType'
import type { UserWalletAddresses } from '@/types/user'
import type { ListData } from '@/types/common.type'

const CONTROLLER = '/user'
const PATHS = {
  profile: '/profile',
  authEntities: '/profile/auth-entities',
  openapiProfile: '/openapi-profile',
}

class User {
  async fetchUserProfile(): Promise<UserProfile> {
    const { data } = await ancient8Api.get<UserProfile>(
      CONTROLLER + PATHS.profile,
    )
    return data
  }

  async fetchAuthEntities(): Promise<any[]> {
    const { data } = await ancient8Api.get(CONTROLLER + PATHS.authEntities)
    return data
  }

  async getWalletAddress(chain: ChainID) {
    const data = await this.fetchAuthEntities()
    const authType = CHAIN_AUTH_MAP[chain]
    if (!data) return ''
    const entity = data.find((e) => e.type === authType)
    return entity?.credential?.walletAddress
  }

  async getAllWalletAddress(): Promise<UserWalletAddresses[]> {
    const data = await this.fetchAuthEntities()
    if (!data) return []
    const entity = data
      .filter((e) => !!e.credential && !!e.credential.walletAddress)
      .map((e) => ({
        walletAddress: e.credential.walletAddress,
        authType: e.type,
      }))
    return entity
  }

  async updateMember() {
    await missionApi.post(CONTROLLER + '/join')
  }

  async searchProfile({
    keyword,
    pageSize = 10,
    page = 1,
  }: {
    keyword: string
    pageSize?: number
    page?: number
  }): Promise<ListData<IA8IdProfile[]>> {
    const { data } = await ancient8Api.get(
      CONTROLLER + PATHS.openapiProfile + '/search',
      {
        params: {
          keyword: keyword.toLocaleLowerCase(),
          pageSize,
          page,
        },
      },
    )

    return {
      data: data.data ?? [],
      total: data.total ?? 0,
    }
  }

  async getProfileByUIDs(payload: { uids: string[] }): Promise<IA8IdProfile[]> {
    const { data } = await ancient8Api.post(
      CONTROLLER + PATHS.openapiProfile,
      payload,
    )

    return data
  }
}

export const UserService = new User()
