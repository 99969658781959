export const HomeIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 15.6611H14.75V8.75114C14.7458 8.65915 14.7213 8.56924 14.678 8.48794C14.6348 8.40664 14.574 8.33599 14.5 8.28114L8.9 3.67114C8.78492 3.57946 8.64213 3.52954 8.495 3.52954C8.34787 3.52954 8.20508 3.57946 8.09 3.67114L2.5 8.28114C2.43155 8.33893 2.37644 8.41087 2.33846 8.49201C2.30048 8.57315 2.28054 8.66155 2.28 8.75114V15.6611H1V8.75114C1.00037 8.47586 1.06151 8.20405 1.17905 7.95513C1.2966 7.70621 1.46765 7.48631 1.68 7.31114L7.3 2.71114C7.63658 2.434 8.05901 2.28247 8.495 2.28247C8.93099 2.28247 9.35342 2.434 9.69 2.71114L15.32 7.31114C15.5323 7.48631 15.7034 7.70621 15.8209 7.95513C15.9385 8.20405 15.9996 8.47586 16 8.75114V15.6611Z"
      fill="currentColor"
    />
    <path
      d="M11.55 13.0812H10.3C10.2741 12.6284 10.0705 12.2041 9.73337 11.9007C9.39626 11.5973 8.95296 11.4393 8.49995 11.4612C8.04694 11.4393 7.60365 11.5973 7.26654 11.9007C6.92943 12.2041 6.72579 12.6284 6.69995 13.0812H5.49995C5.53347 12.3083 5.86428 11.5782 6.4233 11.0434C6.98232 10.5086 7.72632 10.2104 8.49995 10.2112C9.28219 10.1974 10.0389 10.4897 10.6086 11.0259C11.1784 11.562 11.5162 12.2995 11.55 13.0812ZM5.44995 13.0812H6.69995V15.6612H5.44995V13.0812ZM10.3 13.0812H11.55V15.6612H10.3V13.0812Z"
      fill="currentColor"
    />
  </svg>
)
