export const Acceler8PointIcon = ({ size = 20 }: { size?: number }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00004 13.6663C3.31804 13.6663 0.333374 10.6817 0.333374 6.99967C0.333374 3.31767 3.31804 0.333008 7.00004 0.333008C10.682 0.333008 13.6667 3.31767 13.6667 6.99967C13.6667 10.6817 10.682 13.6663 7.00004 13.6663ZM7.00004 4.17101L4.17137 6.99967L7.00004 9.82834L9.82871 6.99967L7.00004 4.17101Z"
      fill="currentColor"
    />
  </svg>
)
