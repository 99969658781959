export default function EmailError() {
  return (
    <div className="flex items-center text-danger gap-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
      >
        <path
          d="M7.00012 1.38184C3.58762 1.38184 0.875122 4.09434 0.875122 7.50684C0.875122 10.9193 3.58762 13.6318 7.00012 13.6318C10.4126 13.6318 13.1251 10.9193 13.1251 7.50684C13.1251 4.09434 10.4126 1.38184 7.00012 1.38184ZM7.00012 12.7568C4.11262 12.7568 1.75012 10.3943 1.75012 7.50684C1.75012 4.61934 4.11262 2.25684 7.00012 2.25684C9.88762 2.25684 12.2501 4.61934 12.2501 7.50684C12.2501 10.3943 9.88762 12.7568 7.00012 12.7568Z"
          fill="currentColor"
        />
        <path
          d="M9.36262 10.5693L7.00012 8.20684L4.63762 10.5693L3.93762 9.86934L6.30012 7.50684L3.93762 5.14434L4.63762 4.44434L7.00012 6.80684L9.36262 4.44434L10.0626 5.14434L7.70012 7.50684L10.0626 9.86934L9.36262 10.5693Z"
          fill="currentColor"
        />
      </svg>
      <div className="text-xs font-light">The email is not valid</div>
    </div>
  )
}
