import axios from 'axios'

import configs from './configs'

import { getAuthorizationHeader } from '@/utils/accessToken'

const { a8Api } = configs

export const fetchRefreshToken = async (): Promise<{
  data: { accessToken: string }
}> => {
  return await axios.get(`${a8Api.auth}/refresh-token`, {
    headers: getAuthorizationHeader(),
  })
}
