import { AuthType } from '@/services/auth/authType'

export type ValueOf<T> = T[keyof T]
export const DATE_FORMAT = 'YYYY/MM/DD HH:mm'
export const YMD = 'YYYYMMDD'

export const DATE_UTC_FORMAT = 'YYYY-MM-DDTHH:MM:ssZ'

export const DEFAULT_PAGE = 1
export const DEFAULT_PAGE_SIZE = 12
export const DEFAULT_PAGE_SIZE_24 = 24
export const ACCELER8_TEAMS_PAGE_SIZE = 10
export const MEDIUM_PAGE_SIZE = 8
export const SMALL_PAGE_SIZE = 6
export const TOP_ITEMS_PAGE_SIZE = 12
export const POPULAR_ITEMS_PAGE_SIZE = 4
export const DEFAULT_LEADERBOARD_PAGE_SIZE = 10
export const DEFAULT_LINKED_MISSION_PAGE_SIZE = 5
export const DEFAULT_CAMPAIGN_MISSION_PAGE_SIZE = 5
export const DEFAULT_OPEN_EDITION_MISSIONS_PAGE_SIZE = 4
export const DEFAULT_GACHA_REWARDS_PAGE_SIZE = 50
export const DEFAULT_GACHA_MISSION_VIEW_SIZE = 30

export enum ChainID {
  A8 = 'CHAIN_ID:A8',
  Arbitrum = 'CHAIN_ID:ARBITRUM',
  BSC = 'CHAIN_ID:BSC',
  Ethereum = 'CHAIN_ID:ETHEREUM',
  Polygon = 'CHAIN_ID:POLYGON',
  Ronin = 'CHAIN_ID:RONIN',
  Default = 'CHAIN_ID:DEFAULT',
  Solana = 'CHAIN_ID:SOLANA',
  Optimism = 'CHAIN_ID:OPTIMISM',
  Base = 'CHAIN_ID:BASE',
  Kroma = 'CHAIN_ID:KROMA',
  Mint = 'CHAIN_ID:MINT',
  Etherlink = 'CHAIN_ID:ETHERLINK',
}

export const CONNECTING_RESTRICT_CHAINS = [ChainID.Ronin]

export enum CommunityChainID {
  Ronin = 'CHAIN_ID:RONIN',
  Solana = 'CHAIN_ID:SOLANA',
  BSC = 'CHAIN_ID:BSC',
  Ethereum = 'CHAIN_ID:ETHEREUM',
  Polygon = 'CHAIN_ID:POLYGON',
  Klaytn = 'CHAIN_ID:KLAYTN',
  BORA = 'CHAIN_ID:BORA',
  ISKRA = 'CHAIN_ID:ISKRA',
  Arbitrum = 'CHAIN_ID:ARBITRUM',
  Avalanche = 'CHAIN_ID:AVALANCHE',
  Lootchain = 'CHAIN_ID:LOOTCHAIN',
  Viction = 'CHAIN_ID:VICTION',
  Base = 'CHAIN_ID:BASE',
  A8 = 'CHAIN_ID:A8',
  Optimism = 'CHAIN_ID:OPTIMISM',
  Bitcoin = 'CHAIN_ID:BITCOIN',
  Linea = 'CHAIN_ID:LINEA',
  Zksync = 'CHAIN_ID:ZKSYNC',
  Starknet = 'CHAIN_ID:STARKNET',
  Mint = 'CHAIN_ID:MINT',
  Kroma = 'CHAIN_ID:KROMA',
  Etherlink = 'CHAIN_ID:ETHERLINK',
}

export enum BreakpointMap {
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1600,
}

export const ChainLabel: Record<ChainID | CommunityChainID, string> = {
  [ChainID.A8]: 'Ancient8',
  [ChainID.Arbitrum]: 'Arbitrum',
  [ChainID.BSC]: 'BNB Chain',
  [ChainID.Ethereum]: 'Ethereum',
  [ChainID.Polygon]: 'Polygon',
  [ChainID.Ronin]: 'Ronin',
  [ChainID.Solana]: 'Solana',
  [CommunityChainID.Klaytn]: 'Klaytn',
  [CommunityChainID.BORA]: 'BORA',
  [CommunityChainID.ISKRA]: 'ISKRA',
  [CommunityChainID.Avalanche]: 'Avalanche',
  [CommunityChainID.Lootchain]: 'Loot',
  [CommunityChainID.Viction]: 'Viction',
  [CommunityChainID.Base]: 'BASE',
  [CommunityChainID.Optimism]: 'Optimism',
  [CommunityChainID.Bitcoin]: 'Bitcoin',
  [CommunityChainID.Linea]: 'Linea',
  [CommunityChainID.Zksync]: 'ZkSync',
  [CommunityChainID.Starknet]: 'Starknet',
  [CommunityChainID.Mint]: 'Mint',
  [CommunityChainID.Kroma]: 'Kroma',
  [CommunityChainID.Etherlink]: 'Etherlink',
  [ChainID.Default]: '',
}

export const CHAIN_AUTH_MAP: Record<ChainID, AuthType> = {
  [ChainID.A8]: AuthType.EVMChain,
  [ChainID.Arbitrum]: AuthType.EVMChain,
  [ChainID.BSC]: AuthType.EVMChain,
  [ChainID.Ethereum]: AuthType.EVMChain,
  [ChainID.Polygon]: AuthType.EVMChain,
  [ChainID.Optimism]: AuthType.EVMChain,
  [ChainID.Base]: AuthType.EVMChain,
  [ChainID.Ronin]: AuthType.Ronin,
  [ChainID.Solana]: AuthType.Solana,
  [ChainID.Kroma]: AuthType.EVMChain,
  [ChainID.Mint]: AuthType.EVMChain,
  [ChainID.Etherlink]: AuthType.EVMChain,
  [ChainID.Default]: AuthType.Default,
}

export enum ProfileItemType {
  MyItems = 'My Items',
  OrderedItems = 'Ordered Items',
}

export type EVMNetworkConfig = {
  rpcUrls: string[]
  chainName: string
  chainId: string
  nativeCurrency: {
    name: string
    decimals: number
    symbol: string
  }
  blockExplorerUrls: string[]
}

export enum PaymentState {
  ERROR,
  LOADING,
  SUCCESS,
}

export enum ClaimButtonState {
  LOGIN,
  CAN_CLAIM,
  CONNECT_WALLET,
  LINK_ADDRESS,
  SWITCH,
  SOLD_OUT,
  CLAIMED,
  CAN_NOT_CLAIM,
}

export const CACHE_TIME = 9999999999

export const OPEN_NEW_TAB = 'open_new_tab'

export const ABOUT_SPACE_3_URL =
  'https://docs.space3.gg/introduction/what-is-space3'
export const ABOUT_A8_URL = 'https://ancient8.gg/'
export const FAQ_URL = 'https://docs.space3.gg/support/faq'
export const HOW_TO_JOIN_LEADERBOARD_URL =
  'https://space3.substack.com/p/space-race-new-leaderboard-new-thrills'

export const MAINTAIN_PASSWORD_KEY = 'maintain_pass'
export const HARD_PASS = 'space3@123'

export const MAINTAIN_TIME = new Date(new Date().getTime() + 2 * 60 * 60 * 1000)

export enum TTL {
  OneMinute = 60 * 1000,
  OneHour = 60 * 60 * 1000,
  OneDay = 24 * 60 * 60 * 1000,
}

export const JS_VOID_0 = 'javascript:void(0)'

export const SPECIAL_CHARS_PATTERN =
  /[`!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~-]/

export const TELEGRAM_BOT_S3_LINK = 'https://t.me/space3_telegram_bot'

export const VISIBLE_DEADLINE_DAYS = 180
