// NOTE: Do not use short import for this file
import React, { type PropsWithChildren } from 'react'

import { ConfigProvider } from 'antd'

import { generateTheme } from './theme'

import NotifyProvider from '../providers/notify.provider'

const themeProvider = (node: React.JSX.Element) => {
  return (
    <ConfigProvider
      theme={generateTheme('dark')}
      prefixCls="space-3"
      iconPrefixCls="space-3-icon"
    >
      <NotifyProvider>{node}</NotifyProvider>
    </ConfigProvider>
  )
}

type Space3ThemeProviderProps = {
  ignoreNotify?: boolean
} & PropsWithChildren

export function Space3ThemeProvider({
  children,
  ignoreNotify,
}: Space3ThemeProviderProps) {
  return (
    <ConfigProvider
      theme={generateTheme('dark')}
      prefixCls="space-3"
      iconPrefixCls="space-3-icon"
    >
      {ignoreNotify ? children : <NotifyProvider>{children}</NotifyProvider>}
    </ConfigProvider>
  )
}

export default themeProvider
