export const CommunityCircleBorder = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
    >
      <circle cx="50" cy="50" r="50" fill="#082723" />
      <circle
        cx="50"
        cy="50"
        r="47.6477"
        stroke="#0FDBD1"
        strokeOpacity="0.3"
        strokeWidth="4.70455"
      />
    </svg>
  )
}
