/**
 * Constructor
 */

type Config = {
  a8AirdropSeasonId: number
  a8AirdropInviteRedirect: string
  gmvnRedemptionId: string
  gmvnFaucetUrl: string
  gmvnFaucetKey: string
  gmvnGachaSlug: string
}

const configs: Config = {
  a8AirdropSeasonId: Number(process.env.NEXT_PUBLIC_A8_AIRDROP_SEASON_ID),
  a8AirdropInviteRedirect: process.env
    .NEXT_PUBLIC_A8_AIRDROP_INVITE_REDIRECT as string,
  gmvnRedemptionId: process.env.NEXT_PUBLIC_GMVN_REDEPMTION_ID as string,
  gmvnFaucetUrl: process.env.NEXT_PUBLIC_GMVN_FAUCET_URL as string,
  gmvnFaucetKey: process.env.NEXT_PUBLIC_GMVN_FAUCET_KEY as string,
  gmvnGachaSlug: process.env.NEXT_PUBLIC_GMVN_GACHA_SLUG as string,
}

/**
 * Module exports
 */
export default configs
