/**
 * Constructor
 */

type Config = {
  A8CommunityId: string
  SP3CommunityId: string
}

const configs: Config = {
  A8CommunityId: process.env.NEXT_PUBLIC_A8_COMMUNITY_ID as string,
  SP3CommunityId: process.env.NEXT_PUBLIC_SP3_COMMUNITY_ID as string,
}

/**
 * Module exports
 */
export default configs
