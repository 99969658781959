import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { customerService } from '@/services/marketplace/customer'

/**
 *  UI Store
 */

export interface CustomerData {
  _id: string
  phone: string
  address: string
  firstName: string
  lastName: string
  country: string
  province: string
  city: string
  zipCode: string
}

export type CustomerStore = {
  customer: Record<string, CustomerData>
  getCustomers: () => void
}

const initialState = {
  customer: {},
}

export const useCustomerStore = create<CustomerStore>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        getCustomers: async () => {
          const bulk: Record<string, CustomerData> = {}
          const data = await customerService.getCustomers()
          for (const customer of data) {
            bulk[customer._id] = customer
          }

          set((state) => ({ ...state, customer: bulk }))
        },
      }),
      {
        name: 'customer',
      },
    ),
  ),
)
