import { Fragment, ReactNode, useEffect } from 'react'
import Script from 'next/script'
import Router, { useRouter } from 'next/router'

import { FlagProvider } from '@unleash/nextjs'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import NProgress from 'nprogress'

import SEO from '@/components/seo/SEO'

import themeProvider from '../theme'
import MaintainProvider from '@/providers/maintain.provider'
import WagmiProvider from '@/providers/wagmi.provider'
import UserProvider from '@/providers/user.provider'
import ChainProvider from '@/providers/chain.provider'
import LoginProvider from '@/providers/login.provider'
import WagmiClientProvider from '@/providers/WagmiClient.provider'
import DisclaimerProvider from '@/providers/disclaimer.provider'

import { GTM_ID, pageview } from '@/libs/gtm'

import 'nprogress/nprogress.css'
import '../../public/antd.min.css'
import '@/theme/index.scss'
import './global.scss'

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

// QueryClient
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
})

export default function App({ Component, pageProps }: any) {
  const router = useRouter()

  const getLayout = Component.getLayout || ((page: ReactNode) => page)

  const isProduction = process.env.NEXT_PUBLIC_ENV === 'production'

  // event tracking for Google Analytics
  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      /* invoke analytics function only for production */
      if (isProduction) pageview(url)
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [isProduction, router.events])

  return (
    <Fragment>
      <SEO>
        <link rel="icon" type="image/x-icon" href="/favicon.ico" sizes="any" />
        <link
          rel="icon"
          type="image/png"
          href="/space3-192.png"
          sizes="192x192"
        />
        <link
          rel="icon"
          type="image/png"
          href="/space3-512.png"
          sizes="512x512"
        />
        <link rel="apple-touch-icon" href="/space3-192.png" />
        <link rel="manifest" href="/manifest.json" />
        {isProduction && (
          <meta
            name="google-site-verification"
            content="dn74jdOdk06lEbIDERcJ-2eb_9mooe9wOUQlDXYP7jI"
          />
        )}
        {isProduction && (
          <meta
            name="google-site-verification"
            content="SJE3Ikpgb9g0TKa91jdzdgUZIxPQby7xwIuY11VWa60"
          />
        )}
        {!isProduction && <meta content="noindex" name="robots" />}
      </SEO>
      <main>
        <FlagProvider>
          <MaintainProvider>
            <QueryClientProvider client={queryClient}>
              <WagmiProvider>
                <UserProvider />
                <ChainProvider />
                <WagmiClientProvider />
                <Hydrate state={pageProps.dehydratedState}>
                  {themeProvider(
                    getLayout(
                      <Fragment>
                        <Component {...pageProps} />
                        <DisclaimerProvider />
                      </Fragment>,
                    ),
                  )}
                </Hydrate>
                <ReactQueryDevtools initialIsOpen={false} />
                <LoginProvider />
              </WagmiProvider>
            </QueryClientProvider>
          </MaintainProvider>
        </FlagProvider>
      </main>
      <Script
        type="module"
        src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.esm.js"
      />

      {/* Google Tag Manager */}
      {/* Only load GTM in production */}
      {isProduction && (
        <Script
          id="gtag-base"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
          }}
        />
      )}
    </Fragment>
  )
}
