import { AuthType } from '@/services/auth/authType'

import { SocialAccounts } from './oauth'

import type { StaticImageData } from 'next/image'

import MetaMask from '@/assets/images/login-modal/metamask.png'
import Coin98 from '@/assets/images/login-modal/coin98.png'
import Coinbase from '@/assets/images/login-modal/coinbase.png'
import Google from '@/assets/images/login-modal/google.png'
import Twitter from '@/assets/images/login-modal/twitter.png'
import Discord from '@/assets/images/login-modal/discord.png'
import Twitch from '@/assets/images/login-modal/twitch.png'
import Ronin from '@/assets/images/login-modal/ronin.png'
import A8 from '@/assets/images/logo/a8-logo-only.svg'

export const extraWalletsInfo = [
  {
    authType: AuthType.EVMChain,
    icon: MetaMask,
    isInstalled: () => window.ethereum?.isMetaMask,
    id: 'metaMask',
    installUrl: 'https://metamask.io/',
  },
  {
    authType: AuthType.Ronin,
    icon: Ronin,
    id: 'roninWallet',
    isInstalled: () => Boolean(window.ronin),
    installUrl: 'https://wallet.skymavis.com/',
  },
  {
    authType: AuthType.EVMChain,
    icon: Coin98,
    isInstalled: () => window.coin98?.provider?.isCoin98,
    id: 'coin98',
    installUrl: 'https://coin98.com/wallet',
  },
  {
    authType: AuthType.EVMChain,
    icon: Coinbase,
    isInstalled: () => true,
    id: 'coinbaseWallet',
    installUrl: 'https://www.coinbase.com/wallet',
  },
  {
    authType: AuthType.EVMChain,
    icon: A8,
    id: 'ancient8',
    isInstalled: () => Boolean(window.ancient8),
    installUrl:
      'https://chromewebstore.google.com/detail/ancient8-wallet-by-coin98/bkgplkpdgidlgmnlhdfakhcjfpfgjjkb',
  },
]

export interface ISocial {
  id: SocialAccounts
  icon: StaticImageData
}

export const socialWallets: ISocial[] = [
  {
    icon: Google,
    id: SocialAccounts.GOOGLE,
  },
  {
    icon: Twitter,
    id: SocialAccounts.Twitter,
  },
  {
    icon: Discord,
    id: SocialAccounts.Discord,
  },
  {
    icon: Twitch,
    id: SocialAccounts.Twitch,
  },
]

export const DISCLAIMER_URL =
  'https://docs.space3.gg/community/disclaimer-and-terms-of-use'

export const LoginType = {
  [SocialAccounts.Discord]: AuthType.Discord,
  [SocialAccounts.Facebook]: AuthType.Facebook,
  [SocialAccounts.Twitch]: AuthType.Twitch,
  [SocialAccounts.GOOGLE]: AuthType.Google,
  [SocialAccounts.Twitter]: AuthType.Twitter,
  [SocialAccounts.Email]: AuthType.EmailOTP,
}

export type TLoginType = keyof typeof LoginType
