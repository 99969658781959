export const RewardCenterIcon = () => (
  <svg
    width="13"
    height="18"
    viewBox="0 0 13 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.39341 7.67275L11.5001 0.635254M4.26341 7.82859L0.666748 0.635254M7.13425 7.34192L3.86258 0.635254M8.46675 0.635254L7.60008 2.71859M1.50008 12.3019C1.50008 13.628 2.02687 14.8998 2.96455 15.8375C3.90223 16.7751 5.174 17.3019 6.50008 17.3019C7.82616 17.3019 9.09793 16.7751 10.0356 15.8375C10.9733 14.8998 11.5001 13.628 11.5001 12.3019C11.5001 10.9758 10.9733 9.70407 10.0356 8.76639C9.09793 7.8287 7.82616 7.30192 6.50008 7.30192C5.174 7.30192 3.90223 7.8287 2.96455 8.76639C2.02687 9.70407 1.50008 10.9758 1.50008 12.3019Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
