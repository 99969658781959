import { Chain } from '@wagmi/core'

export const ancient8 = {
  id: 888888888,
  name: 'Ancient8',
  network: 'ancient8',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.ancient8.gg'] },
    default: { http: ['https://rpc.ancient8.gg'] },
  },
  blockExplorers: {
    etherscan: {
      name: 'Ancient8Scan',
      url: 'https://scan.ancient8.gg',
    },
    default: {
      name: 'Ancient8Scan',
      url: 'https://scan.ancient8.gg',
    },
  },
  contracts: {},
} as const satisfies Chain
