import { CSSProperties, useMemo } from 'react'

export const GradientCupIcon = ({ rank }: { rank?: number }) => {
  const style: CSSProperties | undefined = useMemo(
    () =>
      ({ '2': { filter: 'grayscale(1)' }, '3': { filter: 'brightness(0.6)' } })[
        rank + ''
      ],
    [rank],
  )

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={18}
      fill="none"
      style={style}
    >
      <path
        fill={`${rank && rank > 3 ? '#29333D' : 'url(#minicup)'}`}
        d="M4.359 17.333c0-.681-.044-1.351.011-2.013.06-.703.71-1.258 1.414-1.301.085-.005.14-.02.167-.113.227-.801.457-1.602.686-2.404.004-.014 0-.03 0-.058-.246-.043-.49-.08-.733-.13-1.392-.296-2.588-.944-3.555-1.996-.791-.862-1.318-1.874-1.67-2.983a12.245 12.245 0 0 1-.495-2.63c-.036-.414-.03-.814.164-1.19.356-.684.937-.994 1.696-.997H3.5V.667h10.005v.85h.37c.424.004.848-.01 1.27.015 1.025.063 1.747.88 1.686 1.912a11.73 11.73 0 0 1-.486 2.796c-.398 1.284-1.017 2.438-1.996 3.379-.962.924-2.112 1.478-3.416 1.726-.186.035-.373.062-.58.097l.257.89c.145.51.293 1.019.433 1.53.03.112.082.152.2.158.746.041 1.405.715 1.423 1.463.014.592.005 1.185.006 1.777 0 .02-.005.041-.01.072H4.36v.001Zm-.78-14.138H1.981c-.123 0-.16.064-.152.176.071.949.222 1.881.549 2.779.386 1.06.965 1.986 1.885 2.668.294.219.608.405.97.55-1.153-1.908-1.45-4.02-1.656-6.172v-.001Zm9.846.006c-.204 2.144-.504 4.255-1.65 6.156.037-.013.052-.017.066-.023l.133-.06c.94-.454 1.643-1.157 2.153-2.058.679-1.195.937-2.506 1.041-3.858.01-.132-.062-.167-.18-.166-.488.002-.977 0-1.465 0-.031 0-.062.006-.098.01Z"
      />
      <defs>
        <linearGradient
          id="minicup"
          x1={0.167}
          x2={16.833}
          y1={17.333}
          y2={0.667}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#917134" />
          <stop offset={0.49} stopColor="#FFC75C" />
          <stop offset={1} stopColor="#916C25" />
        </linearGradient>
      </defs>
    </svg>
  )
}
