import React, { useMemo } from 'react'

export const GoldenCrownIcon = ({
  dynamicId = '',
  className,
  size,
}: {
  dynamicId?: string
  className?: string
  size?: 'small' | 'medium' | 'large'
}) => {
  const scale = useMemo(() => {
    switch (size) {
      case 'small':
        return 1
      case 'medium':
        return 2.4
      case 'large':
        return 3.7
    }
  }, [size])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="38"
      viewBox="0 0 49 38"
      fill="none"
      className={className}
      style={{ transform: `scale(${scale})` }}
    >
      <g clipPath={`url(#golden_a_${dynamicId})`}>
        <path
          fill={`url(#golden_b_${dynamicId})`}
          d="M7.284 20.098C.54 10.723 2.461-.58 2.982-2.656A31.42 31.42 0 0 0 .614 5.32 31.247 31.247 0 0 0 2.16 8.556c.067.427.247 1.572.347 1.986A34.412 34.412 0 0 1 .354 6.065C.354 6.13.12 7.249 0 8.187a31.143 31.143 0 0 0 2.982 4.315c0 .065.226.777.24.822A31.414 31.414 0 0 1 .08 8.989 35.39 35.39 0 0 0 0 11.241a29.656 29.656 0 0 0 3.909 4.024c.04.116.073.18.193.478a29.242 29.242 0 0 1-3.855-3.267c0 .647.067 1.19.1 1.611a27.7 27.7 0 0 0 4.802 3.882c.147.291.074.155.34.647A27.003 27.003 0 0 1 .82 15.291c.167.886.32 1.455.4 1.785a26.826 26.826 0 0 0 5.244 3.17c0 .04.38.557.4.596a27.168 27.168 0 0 1-5.116-2.815c.053.24.46 1.585.573 1.87a25.896 25.896 0 0 0 6.177 2.989l.367.414a25.73 25.73 0 0 1-5.877-2.588c.154.356.4.938.847 1.812a26.509 26.509 0 0 0 6.73 2.503l.448.395a26.543 26.543 0 0 1-6.77-2.038c.573.984.866 1.417 1.213 1.941 2.41.89 4.94 1.44 7.511 1.63l.407.292a27.906 27.906 0 0 1-6.964-1.03c.054.072.767 1.094 1.288 1.728 2.58.569 5.229.786 7.87.647.667.401.514.298.8.453a30.723 30.723 0 0 1-7.697-.498c.4.472.86 1.042 1.735 1.94 3.144.433 6.329.514 9.492.24a45.391 45.391 0 0 0 7.337 2.278c.527-.317 1.1-.647 1.587-.99-8.651-1.547-16.535-4.943-21.538-11.917Z"
        />
        <path
          fill={`url(#golden_c_${dynamicId})`}
          d="M41.714 20.098c6.75-9.375 4.822-20.677 4.309-22.754a31.853 31.853 0 0 1 2.361 7.977 34.169 34.169 0 0 1-1.547 3.235c-.06.427-.24 1.572-.347 1.986a34.464 34.464 0 0 0 2.16-4.477c0 .065.228 1.184.348 2.122a30.59 30.59 0 0 1-2.975 4.29c0 .064-.22.776-.24.821a31.437 31.437 0 0 0 3.141-4.309c.047.757.08 1.462.08 2.265a29.742 29.742 0 0 1-3.915 4.024c-.04.116-.067.18-.194.478a28.884 28.884 0 0 0 3.87-3.28c0 .647-.074 1.19-.1 1.611a28.207 28.207 0 0 1-4.803 3.882c-.154.291-.08.155-.347.647a27.321 27.321 0 0 0 4.669-3.325c-.173.886-.32 1.455-.407 1.785a26.57 26.57 0 0 1-5.236 3.17l-.407.596a27.166 27.166 0 0 0 5.116-2.815c-.053.24-.46 1.585-.567 1.87a25.897 25.897 0 0 1-6.177 2.989c-.086.104-.146.162-.373.414a25.73 25.73 0 0 0 5.883-2.588c-.16.356-.4.938-.854 1.812a26.426 26.426 0 0 1-6.73 2.503l-.447.395a26.541 26.541 0 0 0 6.77-2.038c-.573.984-.867 1.417-1.207 1.941a27.605 27.605 0 0 1-7.53 1.611l-.408.291a27.905 27.905 0 0 0 6.958-1.01c-.054.072-.768 1.094-1.288 1.728a29.378 29.378 0 0 1-7.864.647c-.667.401-.514.298-.8.453 2.587.15 5.184-.028 7.724-.53-.4.472-.86 1.041-1.728 1.94a42.688 42.688 0 0 1-9.492.24 45.462 45.462 0 0 1-7.337 2.277c-.527-.317-1.094-.647-1.588-.99 8.638-1.514 16.49-4.91 21.519-11.884Z"
        />
      </g>
      <defs>
        <linearGradient
          id={`golden_b_${dynamicId}`}
          x1={0}
          x2={34.868}
          y1={33.005}
          y2={4.823}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#543F18" />
          <stop offset={0.49} stopColor="#E9C279" />
          <stop offset={1} stopColor="#624A1C" />
        </linearGradient>
        <linearGradient
          id={`golden_c_${dynamicId}`}
          x1={20.195}
          x2={55.035}
          y1={32.972}
          y2={4.801}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#543F18" />
          <stop offset={0.49} stopColor="#E9C279" />
          <stop offset={1} stopColor="#624A1C" />
        </linearGradient>
        <clipPath id={`golden_a_${dynamicId}`}>
          <path fill="#fff" d="M0 0h49v38H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
