/**
 * Constructor
 */

type Config = {
  SP3Profile: string
}

const configs: Config = {
  SP3Profile: process.env.NEXT_PUBLIC_SP3_PROFILE_URI as string,
}

/**
 * Module exports
 */
export default configs
