import React, { Fragment } from 'react'
import clsx from 'clsx'
import { createGlobalState } from 'react-use'

import { Modal } from 'antd'

import LoginModalContent from './LoginModalContent'

import { ModalType } from '@/types/common.type'

import './index.scss'

type A8LoginModalProps = {
  isModalOpen: boolean
  onCloseModal: () => void
}

export const useA8LoginModalValue = createGlobalState(false)

const A8LoginModal = ({ isModalOpen, onCloseModal }: A8LoginModalProps) => {
  return (
    <Modal
      className={clsx(
        '!w-[400px] !rounded-xl overflow-hidden [&>div.space-3-modal-content]:p-0',
      )}
      wrapClassName="a8-login-modal"
      footer={null}
      closable={false}
      destroyOnClose
      open={isModalOpen}
      closeIcon={<Fragment />}
    >
      <LoginModalContent
        modalType={ModalType.Ancient8}
        closeModal={onCloseModal}
      />
    </Modal>
  )
}

export default A8LoginModal
