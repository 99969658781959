/**
 * Constructor
 */

type Config = {
  openEditionContract: string
  gachaPaymentContract: string
  gachaStationContract: string
  votingUsElection: string
}

const configs: Config = {
  openEditionContract: process.env
    .NEXT_PUBLIC_A8_EDITION_CONTRACT_ADDRESS as string,
  gachaPaymentContract: process.env
    .NEXT_PUBLIC_GACHA_PAYMENT_CONTRACT_ADDRESS as string,
  gachaStationContract: process.env
    .NEXT_PUBLIC_GACHA_STATION_CONTRACT_ADDRESS as string,
  votingUsElection: process.env
    .NEXT_PUBLIC_VOTING_US_ELECTION_ADDRESS as string,
}

/**
 * Module exports
 */
export default configs
