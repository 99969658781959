import { Chain } from '@wagmi/core'

export const mintSepoliaTestnet = {
  id: 1687,
  name: 'Mint Sepolia Testnet',
  network: 'mint-sepolia-testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://sepolia-testnet-rpc.mintchain.io'] },
    default: { http: ['https://sepolia-testnet-rpc.mintchain.io'] },
  },
  blockExplorers: {
    etherscan: {
      name: 'MintSepoliaScan',
      url: 'https://sepolia-testnet-explorer.mintchain.io',
    },
    default: {
      name: 'MintSepoliaScan',
      url: 'https://sepolia-testnet-explorer.mintchain.io',
    },
  },
  contracts: {},
} as const satisfies Chain
