import { useRouter } from 'next/router'

import { Button, Col, Row, Typography } from 'antd'

import LinkExternal from '../link-external'

import { useLoginModalValue, useOpenDisclaimer } from '@/components/login'

import { ROUTES } from '@/constants/routes'

import './index.scss'

export default function DisclaimerModalContent() {
  const router = useRouter()
  const isGmVietNamRoute = router.asPath.startsWith(ROUTES.GM_VIETNAM)

  const [, setIsOpenDisclaimer] = useOpenDisclaimer()
  const [, setIsModalOpen] = useLoginModalValue()

  return (
    <Row>
      <Col span={24} className="disclaimer-header rounded-t-[20px]  px-8 py-5">
        <Typography.Text
          className="text-base sm:text-2xl font-semibold text-primary"
          style={{ fontFamily: 'Archivo, sans-serif' }}
        >
          Disclaimer & Terms of Use
        </Typography.Text>
      </Col>
      <Col className="px-8 py-6 overflow-y-auto h-[370px]">
        <Typography.Text className="font-bold sm:text-base text-white">
          1. General
        </Typography.Text>
        <br />
        <Typography.Text className="sm:text-base text-white text-opacity-90">
          These terms and conditions (“Terms”) govern the use of the Website
          (defined below) and the Services (defined below). These Terms also
          include any guidelines, announcements, additional terms, policies, and
          disclaimers made available or issued by us from time to time. These
          Terms constitute a binding and enforceable legal contract between
          Space3 and its affiliates (“Company”, “Space3”, “we”, “us”) and you,
          an end user of the services (“you” or “User”) at https://space3.gg
          (“Services”). By accessing, using or clicking on our website (and all
          related subdomains) or its mobile applications (“Website”) or
          accessing, using or attempting to use the Services, you agree that you
          have read, understood, and to are bound by these Terms and that you
          comply with the requirements listed herein. If you do not agree to all
          of these Terms or comply with the requirements herein, please do not
          access or use the Website or the Services. In addition, when using
          some features of the Services, you may be subject to specific
          additional terms and conditions applicable to those features. We may
          modify, suspend or discontinue the Website or the Services at any time
          and without notifying you. We may also change, update, add or remove
          provisions of these Terms from time to time. Any and all modifications
          or changes to these Terms will become effective upon publication on
          our Website or release to Users. Therefore, your continued use of our
          Services is deemed your acceptance of the modified Terms and rules. If
          you do not agree to any changes to these Terms, please do not access
          or use the Website or the Services. We note that these Terms between
          you and us do not enumerate or cover all rights and obligations of
          each party, and do not guarantee full alignment with needs arising
          from future development. Therefore, our privacy policy, platform
          rules, guidelines and all other agreements entered into separately
          between you and us are deemed supplementary terms that are an integral
          part of these Terms and shall have the same legal effect. Your use of
          the Website or Services is deemed your acceptance of any supplementary
          terms too.
        </Typography.Text>
        <br />
        <br />
        <Typography.Text className="font-bold sm:text-base text-white">
          2. Eligibility
        </Typography.Text>
        <br />
        <Typography.Text className="sm:text-base text-white text-opacity-90">
          Without limiting the foregoing, you represent, warrant, and covenant
          that you are, and for the duration of the time you use the Services,
          will be:
          <br />
          <ul>
            <li>
              As an individual, legal person, or other organization, you have
              full legal capacity and authority to agree and bind yourself to
              these Terms;
            </li>
            <li>
              You are at least 18 or are of legal age to form a binding contract
              under applicable laws;
            </li>
            <li>
              Your use of the Services is not prohibited by applicable law, and
              at all times compliant with applicable law, including but not
              limited to regulations on anti-money laundering, anti-corruption,
              and counter-terrorist financing (“CTF”);
            </li>
            <li>
              You have not been previously suspended or removed from using our
              Services;
            </li>
            <li>
              If you act as an employee or agent of a legal entity, and enter
              into these Terms on their behalf, you represent and warrant that
              you have all the necessary rights and authorizations to bind such
              legal entity;
            </li>
            <li>
              You are solely responsible for use of the Services and, if
              applicable, for all activities that occur on or through your user
              account; and
            </li>
            <li>
              You are not and will not be (a) the subject of economic or trade
              sanctions administered or enforced by any governmental authority
              or otherwise designated on any list of prohibited or restricted
              parties (including but not limited to the United Nations Security
              Council, the European Union, His Majesty’s Treasury, and U.S.
              Department of Treasury), or (b) a citizen, resident, or organized
              in a jurisdiction or territory that is the subject of
              comprehensive country-wide, territory-wide, or regional economic
              sanctions by the United Nations, European Union, any EU country,
              UK Treasury, or the United States. If at any point the above is no
              longer true, you must immediately cease using the Services.
            </li>
          </ul>
        </Typography.Text>
        <br />
        <Typography.Text className="font-bold sm:text-base text-white">
          3. Identity Verification
        </Typography.Text>
        <br />
        <Typography.Text className="sm:text-base text-white text-opacity-90">
          We and our affiliates may, but are not obligated to, collect and
          verify information about you in order to keep appropriate record of
          our users, protect us and the community from fraudulent users, and
          identify traces of money laundering, terrorist financing, fraud and
          other financial crimes, or for other lawful purposes. We may require
          you to provide or verify additional information before permitting you
          to access, use or click on our Website and/or use or attempt to use
          our use or access any Service. We may also suspend, restrict, or
          terminate your access to our Website or any or all of the Services in
          the following circumstances: (a) if we reasonably suspect you of using
          our Website and Services in connection with any prohibited use or
          business; (b) your use of our Website or Services is subject to any
          pending litigation, investigation, or government proceeding and/or we
          perceive a heightened risk of legal or regulatory non-compliance
          associated with your activity; or (c) you take any action that we deem
          as circumventing our controls, including, but not limited to, abusing
          promotions which we may offer from time to time. In addition to
          providing any required information, you agree to allow us to keep a
          record of that information during the period for which your account is
          active and within five (5) years after your account is closed. You
          also authorize us to share your submitted information and
          documentation to third parties to verify the authenticity of such
          information. We may also conduct necessary investigations directly or
          through a third party to verify your identity or protect you and/or us
          from financial crimes, such as fraud, and to take necessary action
          based on the results of such investigations. We will collect, use and
          share such information in accordance with our privacy policy. If you
          provide any information to us, you must ensure that such information
          is true, complete, and timely updated when changed. If there are any
          grounds for believing that any of the information you provided is
          incorrect, false, outdated or incomplete, we reserve the right to send
          you a notice to demand correction, directly delete the relevant
          information, and as the case may be, terminate all or part of the
          Services we provide for you. You shall be fully liable for any loss or
          expense caused to us during your use of the Services. You hereby
          acknowledge and agree that you have the obligation to keep all the
          information accurate, update and correct at all times. We reserve the
          right to confiscate any and all funds that are found to be in
          violation of relevant and applicable AML or CFT laws and regulations,
          and to cooperate with the competent authorities when and if necessary.
        </Typography.Text>
        <br />
        <br />
        <Typography.Text className="font-bold sm:text-base text-white">
          4. Restrictions
        </Typography.Text>
        <br />
        <Typography.Text className="sm:text-base text-white text-opacity-90">
          You shall not access, use or click on our Website and/or use or
          attempt to use the Services in any manner except as expressly
          permitted in these Terms. Without limiting the generality of the
          preceding sentence, you may NOT:
          <br />
          <ul>
            <li>
              Use our Website or use the Services in any dishonest or unlawful
              manner, for fraudulent or malicious activities, or in any manner
              inconsistent with these Terms;
            </li>
            <li>Violate applicable laws or regulations in any manner;</li>
            <li>
              Infringe any proprietary rights, including but not limited to
              copyrights, patents, trademarks, or trade secrets of Space3;
            </li>
            <li>
              Use our Website or use the Services to transmit any data or send
              or upload any material that contains viruses, Trojan horses,
              worms, time-bombs, keystroke loggers, spyware, adware, or any
              other harmful programmes or computer code designed to adversely
              affect the operation of any computer software or hardware;
            </li>
            <li>
              Use any deep linking, web crawlers, bots, spiders or other
              automatic devices, programs, scripts, algorithms or methods, or
              any similar or equivalent manual processes to access, obtain,
              copy, monitor, replicate or bypass the Website or the Services;
            </li>
            <li>
              Make any back-up or archival copies of the Website or any part
              thereof, including disassembling or de-compilation of the Website;
            </li>
            <li>
              Violate public interests, public morals, or the legitimate
              interests of others, including any actions that would interfere
              with, disrupt, negatively affect, or prohibit other Users from
              using our Website and the Services;
            </li>
            <li>
              Use the Services for market manipulation (such as pump and dump
              schemes, wash trading, self-trading, front running, quote
              stuffing, and spoofing or layering, regardless of whether
              prohibited by law);
            </li>
            <li>
              Attempt to access any part or function of the Website without
              authorization, or connect to the Website or Services or any
              Company servers or any other systems or networks of any the
              Services provided through the services by hacking, password mining
              or any other unlawful or prohibited means;
            </li>
            <li>
              Probe, scan or test the vulnerabilities of the Website or Services
              or any network connected to the properties, or violate any
              security or authentication measures on the Website or Services or
              any network connected thereto;
            </li>
            <li>
              Reverse look-up, track or seek to track any information of any
              other Users or visitors of the Website or Services;
            </li>
            <li>
              Take any actions that imposes an unreasonable or
              disproportionately large load on the infrastructure of systems or
              networks of the Website or Services, or the infrastructure of any
              systems or networks connected to the Website or Services;
            </li>
            <li>
              Use any devices, software or routine programs to interfere with
              the normal operation of any transactions of the Website or
              Services, or any other person’s use of the Website or Services; or
            </li>
            <li>
              Forge headers, impersonate, or otherwise manipulate
              identification, to disguise your identity or the origin of any
              messages or transmissions you send to Space3 or the Website.
            </li>
          </ul>
          By accessing the Services, you agree that we have the right to
          investigate any violation of these Terms, unilaterally determine
          whether you have violated these Terms, and take actions under relevant
          regulations without your consent or prior notice.
        </Typography.Text>
        <br />
        <br />
        <Typography.Text className="font-bold sm:text-base text-white">
          5. Termination
        </Typography.Text>
        <br />
        <Typography.Text className="sm:text-base text-white text-opacity-90">
          Space3 may terminate, suspend, or modify your access to Website and/or
          the Services, or any portion thereof, immediately and at any point, at
          its sole discretion. Space3 will not be liable to you or to any third
          party for any termination, suspension, or modification of your access
          to the Services. Upon termination of your access to the Services,
          these Terms shall terminate, except for those clauses that expressly
          or are intended to survive termination or expiry.
        </Typography.Text>
        <br />
        <br />
        <Typography.Text className="font-bold sm:text-base text-white">
          6. Disclaimers
        </Typography.Text>
        <br />
        <Typography.Text className="sm:text-base text-white text-opacity-90">
          OUR SERVICES ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS
          AVAILABLE&quot; BASIS WITHOUT ANY REPRESENTATION OR WARRANTY, WHETHER
          EXPRESS, IMPLIED OR STATUTORY. YOU HEREBY ACKNOWLEDGE AND AGREE THAT
          YOU HAVE NOT RELIED UPON ANY OTHER STATEMENT OR AGREEMENT, WHETHER
          WRITTEN OR ORAL, WITH RESPECT TO YOUR USE AND ACCESS OF THE
          SERVICES.TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
          SPECIFICALLY DISCLAIMS ANY IMPLIED WARRANTIES OF TITLE,
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND/OR
          NON-INFRINGEMENT. SPACE3 DOES NOT MAKE ANY REPRESENTATIONS OR
          WARRANTIES THAT ACCESS TO THE WEBSITE, ANY PART OF THE SERVICES,
          INCLUDING MOBILE SERVICES, OR ANY OF THE MATERIALS CONTAINED THEREIN,
          WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY, OR ERROR-FREE AND WILL NOT
          BE LIABLE FOR ANY LOSSES RELATING THERETO. SPACE3 DOES NOT REPRESENT
          OR WARRANT THAT THE WEBSITE, THE SERVICES OR ANY MATERIALS OF SPACE3
          ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, ERROR-FREE, OR FREE OF
          VIRUSES OR OTHER HARMFUL COMPONENTS.TO THE MAXIMUM EXTENT PERMITTED BY
          APPLICABLE LAW, NONE OF SPACE3 OR ITS AFFILIATES AND THEIR RESPECTIVE
          SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS,
          AGENTS, REPRESENTATIVES, SUPPLIERS OR CONTRACTORS WILL BE LIABLE FOR
          ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, INTANGIBLE OR CONSEQUENTIAL
          LOSSES OR DAMAGES ARISING OUT OF OR RELATING TO:
          <br />
          <ul>
            <li>
              ANY PERFORMANCE OR NON-PERFORMANCE OF THE SERVICES, OR ANY OTHER
              PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF SPACE3
              OR ITS AFFILIATES;
            </li>
            <li>
              ANY AUTHORIZED OR UNAUTHORIZED USE OF THE WEBSITE OR SERVICES, OR
              IN CONNECTION WITH THIS AGREEMENT;
            </li>
            <li>
              ANY INACCURACY, DEFECT OR OMISSION OF ANY DATA OR INFORMATION ON
              THE WEBSITE;
            </li>
            <li>
              ANY ERROR, DELAY OR INTERRUPTION IN THE TRANSMISSION OF SUCH DATA;
            </li>
            <li>
              ANY DAMAGES INCURRED BY ANY ACTIONS, OMISSIONS OR VIOLATIONS OF
              THESE TERMS BY ANY THIRD PARTIES
            </li>
            <li>
              ANY DAMAGE CAUSED BY ILLEGAL ACTIONS OF OTHER THIRD PARTIES OR
              ACTIONS WITHOUT AUTHORIZED BY SPACE3.
            </li>
          </ul>
          EVEN IF SPACE3 KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH
          DAMAGES AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY
          OF ITS ESSENTIAL PURPOSE, EXCEPT TO THE EXTENT OF A FINAL JUDICIAL
          DETERMINATION THAT SUCH DAMAGES WERE A RESULT OF OUR GROSS NEGLIGENCE,
          ACTUAL FRAUD, WILLFUL MISCONDUCT OR INTENTIONAL VIOLATION OF LAW OR
          EXCEPT IN JURISDICTIONS THAT DO NOT ALLOW THE EXCLUSION OR LIMITATION
          OF INCIDENTAL OR CONSEQUENTIAL DAMAGES. THIS PROVISION WILL SURVIVE
          THE TERMINATION OF THESE TERMS.WE MAKE NO WARRANTY AS TO THE MERIT,
          LEGALITY OR JURIDICAL NATURE OF ANY TOKEN SOLD ON OUR PLATFORM
          (INCLUDING WHETHER OR NOT IT IS CONSIDERED A SECURITY OR FINANCIAL
          INSTRUMENT UNDER ANY APPLICABLE SECURITIES LAWS).
        </Typography.Text>
        <br />
        <br />
        <Typography.Text className="font-bold sm:text-base text-white">
          7. Intellectual Property
        </Typography.Text>
        <br />
        <Typography.Text className="sm:text-base text-white text-opacity-90">
          All present and future copyright, title, interests in and to the
          Services, registered and unregistered trademarks, design rights,
          unregistered designs, database rights and all other present and future
          intellectual property rights and rights in the nature of intellectual
          property rights that exist in or in relation to the use and access of
          the Website and the Services are owned by or otherwise licensed to
          Space3. Subject to your compliance with these Terms, we grant you a
          non-exclusive, non-sub license, and any limited license to merely use
          or access the Website and the Services in the permitted hereunder.
          Except as expressly stated in these Terms, nothing in these Terms
          should be construed as conferring any right in or license to our or
          any other third party’s intellectual rights. If and to the extent that
          any such intellectual property rights are vested in you by operation
          of law or otherwise, you agree to do any and all such acts and execute
          any and all such documents as we may reasonably request in order to
          assign such intellectual property rights back to us. You agree and
          acknowledge that all content on the Website must not be copied or
          reproduced, modified, redistributed, used, created for derivative
          works, or otherwise dealt with for any other reason without being
          granted a written consent from us. Third parties participating on the
          Website may permit us to utilize trademarks, copyrighted material, and
          other intellectual property associated with their businesses. We will
          not warrant or represent that the content of the Website does not
          infringe the rights of any third party.
        </Typography.Text>
        <br />
        <br />
        <Typography.Text className="font-bold sm:text-base text-white">
          8. Independent Parties
        </Typography.Text>
        <br />
        <Typography.Text className="sm:text-base text-white text-opacity-90">
          Space3 is an independent contractor but not an agent of you in the
          performance of these Terms. These Terms shall not be interpreted as
          facts or evidence of an association, joint venture, partnership or
          franchise between the parties.
        </Typography.Text>
        <br />
        <br />
        <Typography.Text className="font-bold sm:text-base text-white">
          9. No Professional Advice
        </Typography.Text>
        <br />
        <Typography.Text className="sm:text-base text-white text-opacity-90">
          All information provided on the Website and throughout our Services is
          for informational purposes only and should not be construed as
          professional advice. We do not provide investment advice and any
          content contained on the Website should not be considered as a
          substitute for tailored investment advice. Investing in digital assets
          is highly risky and may lead to a total loss of investment. You must
          have a sufficient understanding of cryptographic tokens, token storage
          mechanisms (such as token wallets), and blockchain technology to
          appreciate the risks involved in dealing in digital assets. You
          understand and agree that the value of digital assets can be volatile,
          and we are not in any way responsible or liable for any losses you may
          incur by using or transferring digital assets in connection with our
          Services. You should not take, or refrain from taking, any action
          based on any information contained on the Website. Before you make any
          financial, legal, or other decisions involving our Services, you
          should seek independent professional advice from an individual who is
          licensed and qualified in the area for which such advice would be
          appropriate.
        </Typography.Text>
        <br />
        <br />
        <Typography.Text className="font-bold sm:text-base text-white">
          10. Indemnification
        </Typography.Text>
        <br />
        <Typography.Text className="sm:text-base text-white text-opacity-90">
          You agree to indemnify and hold harmless Space3 and its affiliates and
          their respective shareholders, members, directors, officers,
          employees, attorneys, agents, representatives, suppliers or
          contractors from and against any potential or actual claims, actions,
          proceedings, investigations, demands, suits, costs, expenses and
          damages (including attorneys’ fees, fines or penalties imposed by any
          regulatory authority) arising out of or related to:
          <br />
          <ul>
            <li>
              Your use of, or conduct in connection with, the Website or
              Services;
            </li>
            <li>Your breach or our enforcement of these Terms; or</li>
            <li>
              Your violation of any applicable law, regulation, or rights of any
              third party during your use of the Website or Services.
            </li>
          </ul>
          If you are obligated to indemnify Space3 and its affiliates and their
          respective shareholders, members, directors, officers, employees,
          attorneys, agents, representatives, suppliers or contractors pursuant
          to these Terms, Space3 will have the right, in its sole discretion, to
          control any action or proceeding and to determine whether Space3
          wishes to settle, and if so, on what terms. Your obligations under
          this indemnification provision will continue even after these Terms
          have expired or been terminated.
        </Typography.Text>
        <br />
        <br />
        <Typography.Text className="font-bold sm:text-base text-white">
          11. Confidentiality
        </Typography.Text>
        <br />
        <Typography.Text className="sm:text-base text-white text-opacity-90">
          You acknowledge that the Services contain Space3&apos;s and its
          affiliates’ trade secrets and confidential information. You agree to
          hold and maintain the Services in confidence, and not to furnish any
          other person any confidential information of the Services or the
          Website. You agree to use a reasonable degree of care to protect the
          confidentiality of the Services. You will not remove or alter any of
          Space3&apos;s or its affiliates’ proprietary notices. Your obligations
          under this provision will continue even after these Terms have expired
          or been terminated.
        </Typography.Text>
        <br />
        <br />
        <Typography.Text className="font-bold sm:text-base text-white">
          12. Anti-Money Laundering
        </Typography.Text>
        <br />
        <Typography.Text className="sm:text-base text-white text-opacity-90">
          Space3 expressly prohibits and rejects the use of the Website or the
          Services for any form of illicit activity, including money laundering,
          terrorist financing or trade sanctions violations. By using the
          Website or the Services, you represent that you are not involved in
          any such activity.
        </Typography.Text>
        <br />
        <br />
        <Typography.Text className="font-bold sm:text-base text-white">
          13. Force Majeure
        </Typography.Text>
        <br />
        <Typography.Text className="sm:text-base text-white text-opacity-90">
          Space3 has no liability to you if it is prevented from or delayed in
          performing its obligations or from carrying on its Services and
          business, by acts, events, omissions or accidents beyond its
          reasonable control, including, without limitation, strikes, failure of
          a utility service or telecommunications network, act of God, war,
          riot, civil commotion, malicious damage, compliance with any law or
          governmental order, rule, regulation, or direction.
        </Typography.Text>
        <br />
        <br />
        <Typography.Text className="font-bold sm:text-base text-white">
          14. Jurisdiction and Governing Law
        </Typography.Text>
        <br />
        <Typography.Text className="sm:text-base text-white text-opacity-90">
          The parties shall attempt in good faith to mutually resolve any and
          all disputes, whether of law or fact, and of any nature whatsoever
          arising from or with respect to these Terms. These Terms and any
          dispute or claim arising out of or in connection with the Services or
          the Website shall be governed by, and construed in accordance with,
          the laws of the British Virgin Islands. Any Dispute arising out of or
          related to these Terms is personal to you and us and will be resolved
          solely through individual arbitration and will not be brought as a
          class arbitration, class action or any other type of representative
          proceeding. There will be no class arbitration or arbitration in which
          an individual attempts to resolve a dispute as a representative of
          another individual or group of individuals. Further, a dispute cannot
          be brought as a class or other type of representative action, whether
          within or outside of arbitration, or on behalf of any other individual
          or group of individuals.
        </Typography.Text>
        <br />
        <br />
        <Typography.Text className="font-bold sm:text-base text-white">
          15. Severability
        </Typography.Text>
        <br />
        <Typography.Text className="sm:text-base text-white text-opacity-90">
          If any provision of these Terms is determined by any court or other
          competent authority to be unlawful or unenforceable, the other
          provisions of these Terms will continue in effect. If any unlawful or
          unenforceable provision would be lawful or enforceable if part of it
          were deleted, that part will be deemed to be deleted, and the rest of
          the provision will continue in effect (unless that would contradict
          the clear intention of the clause, in which case the entirety of the
          relevant provision will be deemed to be deleted).
        </Typography.Text>
        <br />
        <br />
        <Typography.Text className="font-bold sm:text-base text-white">
          16. Notices
        </Typography.Text>
        <br />
        <Typography.Text className="sm:text-base text-white text-opacity-90">
          All notices, requests, demands, and determinations for us under these
          Terms (other than routine operational communications) shall be sent to
          contact@ancient8.gg.
        </Typography.Text>
        <br />
        <br />
        <Typography.Text className="font-bold sm:text-base text-white">
          17. Assignment
        </Typography.Text>
        <br />
        <Typography.Text className="sm:text-base text-white text-opacity-90">
          You may not assign or transfer any right to use the Services or any of
          your rights or obligations under these Terms without prior written
          consent from Space3, including any right or obligation related to the
          enforcement of laws or the change of control. Space3 may assign or
          transfer any or all of its rights or obligations under these Terms, in
          whole or in part, without notice or obtaining your consent or
          approval.
        </Typography.Text>
        <br />
        <br />
        <Typography.Text className="font-bold sm:text-base text-white">
          18. Third Party Rights
        </Typography.Text>
        <br />
        <Typography.Text className="sm:text-base text-white text-opacity-90">
          No third party shall have any rights to enforce any terms contained
          herein.
        </Typography.Text>
        <br />
        <br />
        <Typography.Text className="font-bold sm:text-base text-white">
          19. Third Party Website Disclaimer
        </Typography.Text>
        <br />
        <Typography.Text className="sm:text-base text-white text-opacity-90">
          Any links to third party websites from our Services does not imply
          endorsement by us of any product, service, information or disclaimer
          presented therein, nor do we guarantee the accuracy of the information
          contained on them. If you suffer loss from using such third party
          product and service, we will not be liable for such loss. In addition,
          since we have no control over the terms of use or privacy policies of
          third-party websites, you should carefully read and understand those
          policies.
        </Typography.Text>
        <br />
        <Typography.Text className="sm:text-base text-white text-opacity-90">
          BY MAKING USE OF OUR SERVICES, YOU ACKNOWLEDGE AND AGREE THAT: (A) YOU
          ARE AWARE OF THE RISKS ASSOCIATED WITH TRANSACTIONS OF ENCRYPTED OR
          DIGITAL TOKENS OR CRYPTOCURRENCIES WITH A CERTAIN VALUE THAT ARE BASED
          ON BLOCKCHAIN AND CRYPTOGRAPHY TECHNOLOGIES AND ARE ISSUED AND MANAGED
          IN A DECENTRALIZED FORM (“DIGITIAL CURRENCIES”); (B) YOU SHALL ASSUME
          ALL RISKS RELATED TO THE USE OF THE SERVICES AND TRANSACTIONS OF
          DIGITAL CURRENCIES; AND (C) SPACE3 SHALL NOT BE LIABLE FOR ANY SUCH
          RISKS OR ADVERSE OUTCOMES. AS WITH ANY ASSET, THE VALUES OF DIGITAL
          CURRENCIES ARE VOLATILE AND MAY FLUCTUATE SIGNIFICANTLY AND THERE IS A
          SUBSTANTIAL RISK OF ECONOMIC LOSS WHEN PURCHASING, HOLDING OR
          INVESTING IN DIGITAL CURRENCIES.
        </Typography.Text>
      </Col>
      <Col span={24} className="disclaimer-footer rounded-b-[20px]  px-8 py-5">
        <Row justify="center">
          <Col span={24} className="mb-6">
            <Typography.Text className="text-[13px] sm:text-base italic text-white text-opacity-90">
              By clicking accept, you hereby acknowledge that you have read and
              accept the{' '}
              <LinkExternal href="https://docs.space3.gg/community/disclaimer-and-terms-of-use">
                <span className="text-[#0FDBD1] underline font-bold">
                  Space3 Disclaimer and Terms of Use
                </span>
              </LinkExternal>
            </Typography.Text>
          </Col>
          <Col sm={20} xs={24}>
            <Button
              className="h-12 rounded-[9999px] border-[1px] bg-transparent border-[#0FDBD1] shadow-[0px_0px_4px_0px_#0FDBD1] text-[13px] sm:text-base font-bold text-[#0FDBD1] p-3 hover:!bg-[rgba(15,219,209,0.10)] hover:!border-[#0FDBD1] hover:!text-[#0FDBD1]"
              block
              onClick={() => {
                localStorage.setItem('disclaimer:acception', 'true')
                setIsOpenDisclaimer(false)
                !isGmVietNamRoute && setIsModalOpen(true)
              }}
            >
              I have read and accept the Terms of Use
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
