import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { ChainID } from '@/constants'
import { IChain } from '@/types/chain.type'
import { CHAIN_STATE_DEFAULT } from '@/constants/chain'
import { Sort } from '@/services/chain/type'
import { chainService } from '@/services/chain'

/**
 *  Chain Store
 */

export type ChainState = Record<ChainID, IChain>

export interface ChainStore {
  chain: ChainState
  getChains: () => Promise<void>
}

export const initialState = {
  chain: CHAIN_STATE_DEFAULT,
}

export const useChainStore = create<ChainStore>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        getChains: async () => {
          const chainConfig: ChainState = { ...CHAIN_STATE_DEFAULT }
          const chains = await chainService.getAllChain({ sort: Sort.Latest })
          for (const chain of chains) {
            chainConfig[chain.chainId] = chain
          }
          set({ chain: chainConfig })
        },
      }),
      {
        name: 'chain',
      },
    ),
  ),
)
