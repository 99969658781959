export const NumberOneV2Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={148}
      height={163}
      fill="none"
    >
      <path
        stroke="url(#a)"
        strokeOpacity={0.1}
        strokeWidth={3}
        d="m2.171 161.209-.311 1.761H139.456l.22-1.239 5.29-29.9.311-1.761H97.894L120.126 3.259l.308-1.759h-16.908l-.349.202a181.786 181.786 0 0 1-18.022 9.124l-.012.006a137.36 137.36 0 0 1-18.657 6.826 211.3 211.3 0 0 1-19.395 4.564 214.37 214.37 0 0 1-19.855 2.281l-1.172.08-.203 1.157-3.68 20.93-.31 1.76H73.16l-14.42 81.64H7.681l-.22 1.239-5.29 29.9Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1={2}
          x2={196.569}
          y1={184.469}
          y2={30.759}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#543F18" />
          <stop offset={0.49} stopColor="#E9C279" />
          <stop offset={1} stopColor="#624A1C" />
        </linearGradient>
      </defs>
    </svg>
  )
}
