/**
 * Contractor
 */

type Config = {
  origin: string
  auth: string
  user: string
  client: string
}

const generator = (origin: string): Config => ({
  origin,
  auth: origin + '/auth',
  user: origin + '/user',
  client: origin + '/client',
})

const config: Config = {
  ...generator(process.env.NEXT_PUBLIC_A8_API_URL as string),
}

/**
 * Module exports
 */
export default config
