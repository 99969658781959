import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { ChainID } from '@/constants'
import { chain, onSwitchChain } from '@/helpers/runtime'

/**
 *  UI Store
 */
export type WalletState = {
  chain: ChainID
  walletType: string
  address: string
}

export interface MainStore {
  wallet: WalletState
  visible: boolean
  loading: boolean
  chain: ChainID
  onSwitchWallet: (wallet: WalletState) => void
  onSwitchChainNet: (chain: ChainID) => void
}

const initialState = {
  chain,
  visible: true,
  loading: true,
  wallet: { chain: ChainID.Default, walletType: '', address: '' },
}

export const useMainStore = create<MainStore>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        onSwitchChainNet: (chain) => {
          const nextChain = onSwitchChain(chain)
          set((state) => ({ ...state, chain: nextChain }))
        },
        onSwitchWallet: (wallet) => {
          set((state) => ({ ...state, wallet }))
        },
      }),
      {
        name: 'main',
      },
    ),
  ),
)
