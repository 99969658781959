export const NumberThreeV2Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={136}
      height={136}
      fill="none"
    >
      <path
        stroke="url(#a)"
        strokeOpacity={0.1}
        strokeWidth={3}
        d="m8.235 118.822.007.008.007.008c4.601 5.019 11.472 8.847 20.46 11.575 9.028 2.74 20.446 4.087 34.207 4.087 14.042 0 25.977-1.843 35.76-5.584l.007-.003.008-.003c9.738-3.848 17.135-8.986 22.067-15.48 5.042-6.601 7.569-14.113 7.569-22.483 0-7.713-2.142-13.829-6.641-18.093-3.568-3.381-8.199-5.854-13.831-7.466a56.546 56.546 0 0 0 10.861-5.161l.01-.006c4.656-2.915 8.447-6.601 11.354-11.057 2.963-4.54 4.42-9.83 4.42-15.812 0-6.48-2.266-12.145-6.755-16.927-4.466-4.88-10.835-8.527-18.99-11.017C100.58 2.79 90.797 1.5 79.439 1.5c-11.513 0-21.795 1.603-30.822 4.84-8.874 3.103-16.098 7.35-21.613 12.778-5.518 5.43-8.9 11.556-10.05 18.37v.002l-.725 4.2-.303 1.755H46.23l.194-1.273.363-2.377c.412-2.694 1.952-5.111 4.854-7.247 2.976-2.19 6.68-3.95 11.145-5.251 4.578-1.3 9.401-1.953 14.474-1.953 5.094 0 9.408.597 12.972 1.755l.027.009.028.008c3.697 1.047 6.392 2.58 8.211 4.52l.015.015.015.015c1.917 1.93 2.832 4.117 2.832 6.622 0 3.147-.775 5.758-2.263 7.906-1.55 2.114-3.611 3.92-6.217 5.405-2.64 1.385-5.582 2.433-8.835 3.137-3.172.59-6.341.885-9.509.885H55.117l-.215 1.244-3.45 19.93-.304 1.756h21.936c4.385 0 8.322.418 11.822 1.24l.023.005.023.005c3.439.692 6.078 1.94 8.011 3.67l.033.029.034.027c1.896 1.507 2.883 3.61 2.883 6.505 0 4.289-1.333 7.891-3.982 10.892-2.676 3.033-6.649 5.471-12.038 7.243-5.38 1.646-12.056 2.489-20.063 2.489-9.094 0-15.833-1.21-20.367-3.486-4.351-2.366-6.276-5.393-6.276-9.093V96.724c.116-.772.34-1.84.682-3.222l.462-1.861H2.9l-.27 1.158c-.494 2.113-.812 3.955-.942 5.515-.123 1.36-.187 2.505-.187 3.42 0 6.367 2.271 12.079 6.735 17.088Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1={-2.3}
          x2={155.351}
          y1={149.082}
          y2={9.426}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#764231" />
          <stop offset={0.49} stopColor="#C6896E" />
          <stop offset={1} stopColor="#764231" />
        </linearGradient>
      </defs>
    </svg>
  )
}
