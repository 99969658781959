export const ElectionLogoutIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <g clipPath="url(#clip0_1766_5037)">
      <path
        d="M18.8588 7.35547V10.783H14.2838V13.0705H18.8588V16.498H19.9988V15.358H21.1463V14.218H22.2863V13.0705H23.4263V10.783H22.2863V9.64297H21.1463V8.50297H19.9988V7.35547H18.8588Z"
        fill="#BCFF50"
      />
      <path
        d="M16.5713 16.4971H15.4313V21.0721H16.5713V16.4971Z"
        fill="#BCFF50"
      />
      <path
        d="M16.5713 1.63965H15.4313V7.35465H16.5713V1.63965Z"
        fill="#BCFF50"
      />
      <path
        d="M10.8563 23.3598V22.2123H15.4313V21.0723H10.8563V6.21484H9.71626V23.3598H10.8563Z"
        fill="#BCFF50"
      />
      <path
        d="M9.71625 23.3604H6.28875V24.5004H9.71625V23.3604Z"
        fill="#BCFF50"
      />
      <path
        d="M8.56875 13.0703H7.42875V15.3578H8.56875V13.0703Z"
        fill="#BCFF50"
      />
      <path d="M9.71625 5.0752H7.42875V6.2152H9.71625V5.0752Z" fill="#BCFF50" />
      <path
        d="M6.28876 22.2129H4.00126V23.3604H6.28876V22.2129Z"
        fill="#BCFF50"
      />
      <path
        d="M7.42875 3.92773H5.14125V5.07523H7.42875V3.92773Z"
        fill="#BCFF50"
      />
      <path
        d="M4.00125 21.0723H1.71375V22.2123H4.00125V21.0723Z"
        fill="#BCFF50"
      />
      <path
        d="M5.14125 2.78711H2.86125V3.92711H5.14125V2.78711Z"
        fill="#BCFF50"
      />
      <path
        d="M1.71375 0.5V1.64H0.573753V21.0725H1.71375V2.7875H2.86125V1.64H15.4313V0.5H1.71375Z"
        fill="#BCFF50"
      />
    </g>
    <defs>
      <clipPath id="clip0_1766_5037">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)
