import { Avatar, Spin } from 'antd'

import a8LoadingImg from './a8-loading.png'

export function A8LoadingIcon({ size = 21 }: { size?: number }) {
  return (
    <Spin
      indicator={
        <Avatar
          shape="circle"
          size={size}
          src={a8LoadingImg.src}
          alt="loading-icon"
          className="a8-spin-dot-spin"
        />
      }
    />
  )
}
