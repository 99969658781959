import config from '@/configs'

export const buildUrlFromPath = (path: string): string => {
  if (path.indexOf('http') === 0) return path

  let pathName = path
  if (path[0] === '/') pathName = path.substring(1)

  return config.location.origin + '/' + pathName
}
