import React, { PropsWithChildren, useEffect } from 'react'

import { useChainStore } from '@/stores/chain.store'

function ChainProvider({ children }: PropsWithChildren) {
  const { getChains } = useChainStore()

  useEffect(() => {
    getChains()
  }, [getChains])

  return <>{children}</>
}

export default ChainProvider
