import axios from 'axios'

import configs from '@/configs'
import { getAccessToken } from '@/utils/accessToken'

const token = getAccessToken()

const axiosConfigs = {
  headers: {
    Authorization: !token ? '' : `Bearer ${token}`,
  },
  timeout: 30000,
  baseURL: configs.api.ancient8Api,
}

export const ancient8Api = axios.create(axiosConfigs)
