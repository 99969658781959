import { useCallback } from 'react'

import { notify } from '@/providers/notify.provider'
import { useChainStore } from '@/stores/chain.store'
import { ChainID } from '@/constants'

export const useChainMap = () => {
  const { chain } = useChainStore((state) => state)

  const getChainId = useCallback(
    (chainNumber?: number) => {
      if (!chainNumber) return ChainID.Default
      if (!chain) notify.error({ message: 'Chain configs is not loaded!' })
      return (
        Object.values(chain).find(
          (chainInfo) => chainInfo.chainNumber === chainNumber,
        )?.chainId || ChainID.Default
      )
    },
    [chain],
  )

  return { getChainId }
}
