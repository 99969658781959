export function EmailFilledIcon({ size = 16 }: { size?: number }) {
  return (
    <svg
      viewBox="0 0 14 11"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 12.6 0 L 1.4 0 C 0.63 0 0.006 0.618 0.006 1.373 L 0 9.614 C 0 10.37 0.63 10.988 1.4 10.988 L 12.6 10.988 C 13.37 10.988 14 10.37 14 9.614 L 14 1.373 C 14 0.618 13.37 0 12.6 0 Z M 12.6 2.746 L 7 6.181 L 1.4 2.746 L 1.4 1.373 L 7 4.807 L 12.6 1.373 L 12.6 2.746 Z"
        fill="white"
      />
    </svg>
  )
}
