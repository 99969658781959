export const VerifyIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame">
      <path
        id="Vector"
        d="M6.22917 17.1667C5.20028 17.1667 4.32167 16.8022 3.59333 16.0733C2.86444 15.345 2.5 14.4664 2.5 13.4375C2.5 12.4247 2.85389 11.5564 3.56167 10.8325C4.26944 10.1086 5.12972 9.73555 6.1425 9.71333C6.24917 9.71333 6.35611 9.72138 6.46333 9.73749C6.57 9.75305 6.67667 9.77166 6.78333 9.79333L4.03833 4.28166H8.01333L10 8.25666L11.9875 4.28166H15.9617L13.2367 9.73583C13.3333 9.71416 13.4322 9.69805 13.5333 9.68749C13.635 9.67638 13.7392 9.67083 13.8458 9.67083C14.8664 9.69694 15.7306 10.0719 16.4383 10.7958C17.1461 11.5197 17.5 12.3933 17.5 13.4167C17.5 14.4594 17.1356 15.345 16.4067 16.0733C15.6783 16.8022 14.7928 17.1667 13.75 17.1667C13.5928 17.1667 13.4322 17.1578 13.2683 17.14C13.1044 17.1228 12.9492 17.0894 12.8025 17.04C13.4597 16.7539 13.9208 16.2678 14.1858 15.5817C14.4508 14.895 14.5833 14.1733 14.5833 13.4167C14.5833 12.1378 14.1392 11.0542 13.2508 10.1658C12.3619 9.27749 11.2783 8.83333 10 8.83333C8.72111 8.83333 7.6375 9.27749 6.74917 10.1658C5.86083 11.0547 5.41667 12.1383 5.41667 13.4167C5.41667 14.1794 5.53889 14.9133 5.78333 15.6183C6.02778 16.3239 6.49722 16.7978 7.19167 17.04C7.035 17.0894 6.87722 17.1228 6.71833 17.14C6.56 17.1578 6.39694 17.1667 6.22917 17.1667ZM10 17.1667C8.95833 17.1667 8.07278 16.8019 7.34333 16.0725C6.61389 15.3431 6.24944 14.4578 6.25 13.4167C6.25 12.375 6.61472 11.4894 7.34417 10.76C8.07361 10.0306 8.95889 9.66611 10 9.66666C11.0417 9.66666 11.9272 10.0314 12.6567 10.7608C13.3861 11.4903 13.7506 12.3756 13.75 13.4167C13.75 14.4583 13.3853 15.3439 12.6558 16.0733C11.9264 16.8028 11.0411 17.1672 10 17.1667ZM8.69833 15.3233L10 14.3333L11.3017 15.3233L10.8142 13.7167L12.115 12.7883H10.5083L10 11.0933L9.49167 12.7883H7.885L9.18583 13.7167L8.69833 15.3233Z"
        fill="#0BCBC2"
      />
    </g>
  </svg>
)
