import React, { PropsWithChildren, useEffect } from 'react'
import { useNetwork } from 'wagmi'

import { useMainStore } from '@/stores/main.store'
import useConnectors from '@/hooks/login/useConnectors'
import { useChainMap } from '@/hooks/useChainMap'

import { ChainID } from '@/constants'

function WagmiClientProvider({ children }: PropsWithChildren) {
  const { walletAddress, isConnected, currentConnector } = useConnectors()
  const { chain } = useNetwork()
  const { getChainId } = useChainMap()

  const { onSwitchWallet } = useMainStore((state) => state)

  useEffect(() => {
    if (isConnected)
      return onSwitchWallet({
        address: walletAddress,
        walletType: currentConnector?.id || '',
        chain: getChainId(chain?.id),
      })
    onSwitchWallet({
      address: '',
      walletType: '',
      chain: ChainID.Default,
    })
  }, [
    chain?.id,
    currentConnector?.id,
    getChainId,
    isConnected,
    onSwitchWallet,
    walletAddress,
  ])

  return <>{children}</>
}

export default WagmiClientProvider
