export const OrangeTriangleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
  >
    <path
      d="M1.25 0.870834L11 6.5L1.25 12.1292L1.25 0.870834Z"
      stroke="#FF7300"
    />
  </svg>
)
