export const FeatureFlag = {
  Maintenance: 'MAINTENANCE',
  ProductAdvancedSearch: 'release_rollout_advantage_reward_search',
  BountyAdvancedSearch: 'release_rollout_advantage_mission_search',
  CommunityOverview: 'release_rollout_community_overview',
  Affiliate: 'release_rollout_affiliate',
  Acceler8: 'release_rollout_acceler8',
  Space3Airdrop: 'release_rollout_space3_airdrop',
  A8Airdrop: 'release_rollout_a8_airdrop',
  Gacha: 'release_rollout_gacha',
  A8Wallet: 'release_rollout_a8_wallet',
  A8Mainnet: 'release_rollout_a8_mainnet',
  A8UltimateGreen: 'release_rollout_a8_ultimate_green',
  Leaderboard: 'release_rollout_leaderboard',
  Launchpad: 'release_rollout_launchpad',
  EliteNft: 'release_rollout_elite_nft',
  GmVietNam: 'release_rollout_gmvietnam',
  AirdropCheckpoint: 'release_rollout_ldb_checkpoint',
  AirdropUserExport: 'release_rollout_ldb_user_export',
  CampaignLeaderboard: 'release_rollout_campaign_leaderboard',
  CampaignCheckpoint: 'release_rollout_campaign_ldb_checkpoint',
  ElectionCampaign: 'release_rollout_election_campaign',
  GameOnSummerCampaign: 'release_rollout_game_on_summer_campaign',
}
