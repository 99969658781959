import { Image, Typography } from 'antd'
import Icon from '@ant-design/icons'

import A8Loading from '@/assets/images/login-modal/a8-loading.png'
import { A8BrandIcon } from '@/assets/images/icon'

function A8LoginLoading() {
  return (
    <div className="relative rounded-xl flex flex-col gap-4 bg-b1 p-10 text-center">
      <div className="a8-logo">
        <Icon
          component={() => (
            <A8BrandIcon color="#CEFF70" className="w-[210px] h-[38px]" />
          )}
        />
      </div>
      <div>
        <Typography.Text className="text-[20px] font-[500] text-white">
          SIGN IN
        </Typography.Text>
      </div>
      <Image
        src={A8Loading.src}
        preview={false}
        alt="success-loading"
        className="!w-[93px] !h-[93px] mx-auto animate-spin"
      />
    </div>
  )
}

export default A8LoginLoading
