export const CurvedMarkerIcon = () => {
  return (
    <svg
      viewBox="0 0 38 10"
      width={35}
      height={10}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter
          id="filter0_ddddd_26000_48769"
          x="0.0656281"
          y="0.366251"
          width="255.869"
          height="412.877"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0313726 0 0 0 0 0.0627451 0 0 0 0 0.207843 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_26000_48769"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="16.56" />
          <feGaussianBlur stdDeviation="18.63" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0313726 0 0 0 0 0.0627451 0 0 0 0 0.207843 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_26000_48769"
            result="effect2_dropShadow_26000_48769"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="68.31" />
          <feGaussianBlur stdDeviation="34.155" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0313726 0 0 0 0 0.0627451 0 0 0 0 0.207843 0 0 0 0.17 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_26000_48769"
            result="effect3_dropShadow_26000_48769"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="155.25" />
          <feGaussianBlur stdDeviation="46.575" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0313726 0 0 0 0 0.0627451 0 0 0 0 0.207843 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_dropShadow_26000_48769"
            result="effect4_dropShadow_26000_48769"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="275.31" />
          <feGaussianBlur stdDeviation="54.855" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0313726 0 0 0 0 0.0627451 0 0 0 0 0.207843 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="effect4_dropShadow_26000_48769"
            result="effect5_dropShadow_26000_48769"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect5_dropShadow_26000_48769"
            result="shape"
          />
        </filter>
      </defs>
      <path
        d="M 26.2 2.502 L 20.603 8.695 C 19.754 9.635 18.246 9.635 17.397 8.695 L 11.8 2.502 L 26.2 2.502 Z"
        fill="#2EF2FF"
        style={{ transformOrigin: '19px 6px' }}
      />
      <g
        filter="url(#filter0_ddddd_26000_48769)"
        transform="matrix(1.028571009636, 0, 0, 0.996082961559, -98.992031373353, -12.30276328663)"
        style={{ transformOrigin: '117.992px 18.3028px' }}
      >
        <path
          d="M 135.492 17.937 C 130.046 15.902 124.149 14.789 117.992 14.789 C 111.836 14.789 105.939 15.902 100.492 17.937"
          stroke="#2EF2FF"
          strokeWidth="4.14"
          style={{ fill: 'rgba(0, 0, 0, 0)' }}
        />
      </g>
    </svg>
  )
}
