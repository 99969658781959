import { useRouter } from 'next/router'

import DisclaimerModal from '@/components/systems/disclaimer-modal'
import DisclaimerA8Modal from '@/components/systems/disclaimer-a8-modal'

import { useOpenDisclaimer } from '@/components/login'

function DisclaimerProvider() {
  const router = useRouter()

  const [isModalOpen, setIsModalOpen] = useOpenDisclaimer()
  const isA8Route = router.asPath.startsWith('/a8-airdrop')

  if (router.isReady && isA8Route) {
    return (
      <DisclaimerA8Modal
        isModalOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
      />
    )
  }

  return (
    <DisclaimerModal
      isModalOpen={isModalOpen}
      onCloseModal={() => setIsModalOpen(false)}
    />
  )
}

export default DisclaimerProvider
