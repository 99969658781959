export const AvailableBadgeStatusIcon = ({
  className,
}: {
  className?: string
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    className={className}
  >
    <path
      fill="#0FDBD1"
      d="M8 1.333A6.67 6.67 0 0 0 1.333 8 6.67 6.67 0 0 0 8 14.667 6.67 6.67 0 0 0 14.666 8 6.67 6.67 0 0 0 8 1.333Zm-.667 10L4.404 8.895l.803-1.083 2.126 1.635L11 5.667l.94.946-4.607 4.72Z"
    />
  </svg>
)
