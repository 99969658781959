import React, { useMemo } from 'react'

export const SilverCrownIcon = ({
  dynamicId = '',
  className,
  size,
}: {
  dynamicId?: string
  className?: string
  size?: 'small' | 'medium' | 'large'
}) => {
  const scale = useMemo(() => {
    switch (size) {
      case 'small':
        return 1
      case 'medium':
        return 2.4
      case 'large':
        return 3.7
    }
  }, [size])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={49}
      height={41}
      fill="none"
      className={className}
      style={{ transform: `scale(${scale})` }}
    >
      <g clipPath={`url(#silver_a_${dynamicId})`}>
        <path
          fill={`url(#silver_b_${dynamicId})`}
          d="M3.064.192c.06-.307 0-.236-.08.141.054-.109.08-.153.08-.14Z"
        />
        <path
          fill={`url(#silver_c_${dynamicId})`}
          d="M7.284 22.84C.54 13.57 2.461 2.39 2.982.336a30.82 30.82 0 0 0-2.368 7.89 30.76 30.76 0 0 0 1.547 3.199c.067.422.247 1.555.347 1.964A33.876 33.876 0 0 1 .354 8.961c0 .064-.234 1.171-.354 2.1a30.792 30.792 0 0 0 2.982 4.267c0 .064.226.768.24.813A31.088 31.088 0 0 1 .08 11.854 34.62 34.62 0 0 0 0 14.08a29.486 29.486 0 0 0 3.909 3.98c.04.115.073.179.193.473a29.165 29.165 0 0 1-3.855-3.231c0 .64.067 1.177.1 1.593a27.644 27.644 0 0 0 4.802 3.84c.147.287.074.153.34.64a27.01 27.01 0 0 1-4.669-3.29c.167.877.32 1.44.4 1.766a26.88 26.88 0 0 0 5.244 3.136c0 .038.38.55.4.588a27.26 27.26 0 0 1-5.116-2.783c.053.236.46 1.567.573 1.849a26.018 26.018 0 0 0 6.177 2.956l.367.41a25.88 25.88 0 0 1-5.877-2.56c.154.352.4.928.847 1.792a26.692 26.692 0 0 0 6.73 2.476l.448.39a26.758 26.758 0 0 1-6.77-2.015c.573.973.866 1.401 1.213 1.92 2.41.88 4.94 1.423 7.511 1.612l.407.288a28.197 28.197 0 0 1-6.964-1.017c.054.07.767 1.081 1.288 1.708 2.58.562 5.229.777 7.87.64.667.397.514.294.8.448a31.048 31.048 0 0 1-7.697-.493c.4.467.86 1.03 1.735 1.92a43.16 43.16 0 0 0 9.492.236 45.76 45.76 0 0 0 7.337 2.253c.527-.314 1.1-.64 1.587-.98-8.651-1.528-16.535-4.888-21.538-11.786Z"
        />
        <path
          fill={`url(#silver_d_${dynamicId})`}
          d="M45.941.192c-.066-.307 0-.236.08.141a.67.67 0 0 0-.08-.14Z"
        />
        <path
          fill={`url(#silver_e_${dynamicId})`}
          d="M41.714 22.84c6.75-9.271 4.822-20.45 4.309-22.504a31.245 31.245 0 0 1 2.361 7.89 33.637 33.637 0 0 1-1.547 3.199c-.06.422-.24 1.555-.347 1.964a33.93 33.93 0 0 0 2.16-4.428c0 .064.228 1.171.348 2.1a30.247 30.247 0 0 1-2.975 4.242c0 .064-.22.767-.24.812a31.107 31.107 0 0 0 3.141-4.261c.047.748.08 1.446.08 2.24a29.561 29.561 0 0 1-3.915 3.98c-.04.114-.067.178-.194.473a28.812 28.812 0 0 0 3.87-3.244c0 .64-.074 1.177-.1 1.593a28.149 28.149 0 0 1-4.803 3.84c-.154.287-.08.153-.347.64a27.325 27.325 0 0 0 4.669-3.29c-.173.877-.32 1.44-.407 1.766a26.62 26.62 0 0 1-5.236 3.136l-.407.588a27.259 27.259 0 0 0 5.116-2.783c-.053.236-.46 1.567-.567 1.849a26.019 26.019 0 0 1-6.177 2.956c-.086.103-.146.16-.373.41a25.882 25.882 0 0 0 5.883-2.56c-.16.352-.4.928-.854 1.792a26.608 26.608 0 0 1-6.73 2.476l-.447.39a26.757 26.757 0 0 0 6.77-2.015c-.573.973-.867 1.401-1.207 1.92a27.872 27.872 0 0 1-7.53 1.593l-.408.288a28.192 28.192 0 0 0 6.958-.998c-.054.07-.768 1.081-1.288 1.708a29.697 29.697 0 0 1-7.864.64c-.667.397-.514.294-.8.448a31 31 0 0 0 7.724-.525c-.4.467-.86 1.03-1.728 1.92a43.16 43.16 0 0 1-9.492.236 45.83 45.83 0 0 1-7.337 2.253c-.527-.314-1.094-.64-1.588-.98 8.638-1.496 16.49-4.856 21.519-11.754Z"
        />
      </g>
      <defs>
        <linearGradient
          id={`silver_b_${dynamicId}`}
          x1={2.984}
          x2={3.175}
          y1={0.333}
          y2={0.274}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#797979" />
          <stop offset={0.49} stopColor="#fff" />
          <stop offset={1} stopColor="#696969" />
        </linearGradient>
        <linearGradient
          id={`silver_c_${dynamicId}`}
          x1={0}
          x2={34.563}
          y1={35.606}
          y2={7.361}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#797979" />
          <stop offset={0.49} stopColor="#fff" />
          <stop offset={1} stopColor="#696969" />
        </linearGradient>
        <linearGradient
          id={`silver_d_${dynamicId}`}
          x1={45.914}
          x2={46.109}
          y1={0.333}
          y2={0.271}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#797979" />
          <stop offset={0.49} stopColor="#fff" />
          <stop offset={1} stopColor="#696969" />
        </linearGradient>
        <linearGradient
          id={`silver_e_${dynamicId}`}
          x1={20.195}
          x2={54.73}
          y1={35.574}
          y2={7.34}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#797979" />
          <stop offset={0.49} stopColor="#fff" />
          <stop offset={1} stopColor="#696969" />
        </linearGradient>
        <clipPath id={`silver_a_${dynamicId}`}>
          <path fill="#fff" d="M0 0h49v41H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
