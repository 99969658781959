export const ROUTES = {
  HOME: '/',
  MAINTAIN: '/maintain',
  MISSION: {
    INDEX: '/missions',
    DETAIL: '/missions/[slug]',
  },
  REWARD_CENTER: {
    INDEX: '/rewards-center',
    DETAIL: '/rewards-center/[slug]',
  },
  PROFILE: {
    INDEX: '/profile',
  },
  SOCIAL_TASK: {
    TELEGRAM: '/social-task/telegram',
  },
  QUESTION_TASK: {
    QUESTION_ANSWER: '/question-task/question-answer/[id]',
    QUESTION_QUIZ: '/question-task/question-quiz/[id]',
  },
  OAUTH: {
    INDEX: '/oauth',
  },
  OAUTH2: {
    INDEX: '/oauth2',
  },
  COMMUNITY: {
    INDEX: '/games',
    DETAILS: '/games/[id]',
  },
  LEADERBOARD: {
    INDEX: '/leaderboard',
    DETAIL: '/leaderboard/[slug]',
  },
  A8_LAYER_2: {
    INDEX: '/A8Layer2',
  },
  A8_AIRDROP: {
    INDEX: '/a8-airdrop',
  },
  ACCELER8: {
    INDEX: '/acceler8',
  },
  GACHA: {
    INDEX: '/gacha',
    DETAIL: '/gacha/[slug]',
  },
  INVITE: '/[slug]/invite',
  CAMPAIGN: {
    DETAIL: '/campaign/[slug]',
    ULTIMATE_GREEN_SUMMER: '/campaign/ancient8-ultimate-green-summer',
    GAME_ON_SUMMER: '/campaign/game-on-summer',
  },
  GM_VIETNAM: '/gmvn',
  ELECTION: {
    INDEX: '/onchainelectioncampaign',
    VOTE: '/onchainelectioncampaign/vote',
  },
}

export const DYNAMIC_INVITE = {
  A8_AIRDROP: 'a8-airdrop',
}
