export type Data<T> = T & {
  _id: string
}

export type Pagination<T> = T & {
  nextPage: number | null
}

export type TimestampData = {
  createdAt: string
  updatedAt: string
}

export type ListData<T> = { data: T; total: number }

export type SearchDto = {
  search?: string
}

export type PaginationDto = {
  offset?: number

  limit?: number
}

export type NativeCurrency = {
  name: string
  symbol: string
  decimals: number
}

export type Config = {
  explorer: string
  chainId: number
  rpcUrl: string
  chainName: string
  nativeCurrency: NativeCurrency
  chainIdHex: string
}

export enum TokenSymbol {
  ETH = 'eth',
  USDC = 'usdc',
  A8 = 'a8',
}

export enum ModalType {
  Space3 = 'SPACE3',
  Ancient8 = 'ANCIENT8',
}

export type CommonIconProps<T = NonNullable<unknown>> = {
  size?: number
} & T

export type FaqItemType = {
  question: string
  answer: string
}
