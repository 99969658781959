import a8Api from './a8Api.config'
import oauth from './oauth.config'

const configs = {
  a8Api,
  oauth,
}

/**
 * Module exports
 */
export default configs
