import { Modal } from 'antd'

import DisclaimerModalContent from './DisclaimerModalContent'

import './index.scss'

type DisclaimerModalProps = {
  isModalOpen: boolean
  onCloseModal: () => void
}

const DisclaimerModal = ({ isModalOpen }: DisclaimerModalProps) => {
  return (
    <Modal
      className="disclaimer-container rounded-2xl !p-0 bg-transparent mobile:top-4"
      footer={null}
      closable={false}
      destroyOnClose
      open={isModalOpen}
    >
      <DisclaimerModalContent />
    </Modal>
  )
}

export default DisclaimerModal
