import React from 'react'

export const GoToIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21.143 9.714V4m0 0h-5.714m5.714 0-8 8M12 4H6.286A2.286 2.286 0 0 0 4 6.286v11.428A2.286 2.286 0 0 0 6.286 20h12.571a2.286 2.286 0 0 0 2.286-2.286v-4.571"
      />
    </svg>
  )
}
