import clsx from 'clsx'

import { useLoginModalValue } from '.'

export default function CloseIcon({ className }: { className?: string }) {
  const [, setLoginModalValue] = useLoginModalValue()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      onClick={() => setLoginModalValue(false)}
      className={clsx(
        'absolute text-b3 hover:text-primary right-4 top-4 h-8 w-8 cursor-pointer',
        className,
      )}
    >
      <path
        d="M7 7L17 17M7 17L17 7"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
