/**
 * Constructor
 */

type Config = {
  missionId: string
  linkedGachaID: string
  kamalaRedemptionId: string
  trumpRedemptionId: string
  hairyMultiplierId: string
  a8StakerMultiplierId: string
  a8StakerHairyMultiplierId: string
  electionWhitelist: string
  endedDate: string
}

const configs: Config = {
  missionId: process.env.NEXT_PUBLIC_MISSION_ID as string,
  linkedGachaID: process.env.NEXT_PUBLIC_LINKED_GACHA_ID as string,
  kamalaRedemptionId: process.env.NEXT_PUBLIC_KAMALA_REDEMPTION_ID as string,
  trumpRedemptionId: process.env.NEXT_PUBLIC_TRUMP_REDEMPTION_ID as string,
  hairyMultiplierId: process.env.NEXT_PUBLIC_HAIRY_MULTIPLIER_ID as string,
  a8StakerMultiplierId: process.env
    .NEXT_PUBLIC_A8_STAKER_MULTIPLIER_ID as string,
  a8StakerHairyMultiplierId: process.env
    .NEXT_PUBLIC_A8_STAKER_HAIRY_MULTIPLIER_ID as string,
  electionWhitelist: process.env.NEXT_PUBLIC_ELECTION_WHITELIST as string,
  endedDate: process.env.NEXT_PUBLIC_ENDED_DATE as string,
}

/**
 * Module exports
 */
export default configs
