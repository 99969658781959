type CheckMarkIconProps = {
  color?: string
  size?: number
}

export const CheckMarkIcon = ({ size = 32, color }: CheckMarkIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.24 4.45312L10.66 24.0131L1.76 15.1131L0 16.8731L10.66 27.5531L32 6.21312L30.24 4.45312Z"
      fill={color || 'currentColor'}
    />
  </svg>
)
