import { Image } from 'antd'

import HeaderIcon from '@/assets/images/logo/brand.svg'
import SuccessLoading from '@/assets/images/login-modal/success-loading-icon.png'

const LoginLoading = () => {
  return (
    <div className="relative rounded-xl flex flex-col gap-4 bg-b1 p-10 text-center">
      <Image
        preview={false}
        src={HeaderIcon.src}
        alt="logo-login"
        className="w-full h-[66px]"
      />
      <div className="text-[20px]/[135%] font-bold text-primary">SIGN IN</div>
      <Image
        src={SuccessLoading.src}
        preview={false}
        alt="success-loading"
        className="!w-[93px] !h-[93px] mx-auto animate-spin"
      />
    </div>
  )
}

export default LoginLoading
