export const UnavailableBadgeStatusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M7 1.3125C5.87512 1.3125 4.7755 1.64607 3.8402 2.27102C2.90489 2.89597 2.17591 3.78423 1.74544 4.82349C1.31496 5.86274 1.20233 7.00631 1.42179 8.10958C1.64124 9.21284 2.18292 10.2263 2.97833 11.0217C3.77374 11.8171 4.78716 12.3588 5.89043 12.5782C6.99369 12.7977 8.13726 12.685 9.17651 12.2546C10.2158 11.8241 11.104 11.0951 11.729 10.1598C12.3539 9.2245 12.6875 8.12488 12.6875 7C12.6858 5.49212 12.086 4.04649 11.0197 2.98026C9.95351 1.91402 8.50789 1.31424 7 1.3125ZM9.05933 8.44067C9.10002 8.48128 9.13231 8.52951 9.15435 8.5826C9.17639 8.63569 9.18775 8.69261 9.18779 8.7501C9.18782 8.80758 9.17652 8.86451 9.15453 8.91763C9.13255 8.97074 9.10031 9.01901 9.05966 9.05966C9.01901 9.1003 8.97075 9.13254 8.91763 9.15453C8.86451 9.17651 8.80759 9.18781 8.7501 9.18778C8.69261 9.18775 8.6357 9.17639 8.5826 9.15435C8.52951 9.13231 8.48128 9.10002 8.44068 9.05933L7 7.61865L5.55933 9.05933C5.47726 9.14122 5.36604 9.18719 5.2501 9.18712C5.13416 9.18706 5.02299 9.14098 4.94101 9.059C4.85902 8.97702 4.81294 8.86584 4.81288 8.7499C4.81282 8.63396 4.85878 8.52274 4.94068 8.44067L6.38135 7L4.94068 5.55933C4.85878 5.47726 4.81282 5.36604 4.81288 5.2501C4.81294 5.13416 4.85902 5.02298 4.94101 4.941C5.02299 4.85902 5.13416 4.81294 5.2501 4.81288C5.36604 4.81281 5.47726 4.85878 5.55933 4.94067L7 6.38135L8.44068 4.94067C8.52275 4.85878 8.63397 4.81281 8.74991 4.81288C8.86584 4.81294 8.97702 4.85902 9.059 4.941C9.14098 5.02298 9.18706 5.13416 9.18713 5.2501C9.18719 5.36604 9.14122 5.47726 9.05933 5.55933L7.61865 7L9.05933 8.44067Z"
      fill="#F45050"
    />
  </svg>
)
