export const InfiniteIcon = ({ size = 40 }: { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M22.916 15.6243L23.3327 14.9993C24.3821 13.6001 25.8452 12.5666 27.5146 12.0451C29.1841 11.5236 30.9753 11.5406 32.6346 12.0937C34.2938 12.6467 35.737 13.7079 36.7597 15.1268C37.7824 16.5456 38.3327 18.2503 38.3327 19.9994C38.3327 21.7484 37.7824 23.4531 36.7597 24.8719C35.737 26.2908 34.2938 27.352 32.6346 27.905C30.9753 28.4581 29.1841 28.4751 27.5146 27.9536C25.8452 27.4321 24.3821 26.3986 23.3327 24.9994L16.666 14.9993C15.6166 13.6001 14.1535 12.5666 12.4841 12.0451C10.8146 11.5236 9.02338 11.5406 7.36412 12.0937C5.70485 12.6467 4.26167 13.7079 3.239 15.1268C2.21633 16.5456 1.66602 18.2503 1.66602 19.9994C1.66602 21.7484 2.21633 23.4531 3.239 24.8719C4.26167 26.2908 5.70485 27.352 7.36412 27.905C9.02338 28.4581 10.8146 28.4751 12.4841 27.9536C14.1535 27.4321 15.6166 26.3986 16.666 24.9994L17.2227 24.166"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
