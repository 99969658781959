import React, { Fragment, PropsWithChildren, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { hasCookie } from 'cookies-next'

import { useFlag, useFlagsStatus } from '@unleash/nextjs'

import FirstPageLoading from '@/components/loading/FirstPageLoading'

import { FeatureFlag } from '@/constants/feature-flags'
import { ROUTES } from '@/constants/routes'
import { MAINTAIN_PASSWORD_KEY, TTL } from '@/constants'

function MaintainProvider({ children }: PropsWithChildren) {
  const router = useRouter()
  const isMaintaining = useFlag(FeatureFlag.Maintenance)
  const { flagsReady, flagsError } = useFlagsStatus()
  const [waitingForFlags, setWaitingForFlags] = useState(false)

  useEffect(() => {
    if (!flagsReady && flagsError) {
      setWaitingForFlags(true)

      // waiting for 10 minutes before redirect to maintain mode
      const timer = setTimeout(
        () => router.replace(ROUTES.MAINTAIN),
        10 * TTL.OneMinute,
      )

      return () => clearTimeout(timer)
    }

    if (flagsReady) setWaitingForFlags(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagsReady, flagsError])

  useEffect(() => {
    if (isMaintaining && !hasCookie(MAINTAIN_PASSWORD_KEY)) {
      router.replace(ROUTES.MAINTAIN)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMaintaining])

  if (waitingForFlags) return <FirstPageLoading isLoading={true} />
  return <Fragment>{children}</Fragment>
}

export default MaintainProvider
