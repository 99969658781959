import { theme, ThemeConfig } from 'antd'

export type ThemeMode = 'light' | 'dark'

/**
 * WARNING:
 *
 * token was generated from the following website:
 * https://ant.design/theme-editor
 *
 * Double check before change any prop because
 * it can replace or overwrite some another props
 *
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const generateTheme = (mode: ThemeMode): ThemeConfig => ({
  algorithm: theme.defaultAlgorithm,
  token: {
    colorPrimary: '#0fdbd1',
    colorSuccess: '#0fdbd1',
    colorWarning: '#ff7300',
    colorError: '#db4646',
    colorInfo: '#0fdbd1',
    colorTextBase: '#ffffff',
    colorBgBase: '#060708',
    colorBgSpotlight: '#151B21',
    colorPrimaryBg: '#112626',
    colorPrimaryBorder: '#0fdbd1',
    colorPrimaryBgHover: '#0fdbd1',
    colorPrimaryBorderHover: '#0fdbd1',
    colorSuccessBg: '#121416',
    colorWarningBg: '#121416',
    colorErrorBg: '#121416',
    colorBorder: '#4D5A66',
    colorBorderSecondary: '#1C2023',
    colorBgLayout: '#060708',
    fontFamily: 'Archivo, sans-serif',
    borderRadius: 8,
    borderRadiusLG: 12,
    borderRadiusSM: 4,
  },
  components: {
    Table: {
      colorBgContainer: 'transparent',
      colorBorder: 'transparent',
    },
    Tabs: {
      fontSize: 16,
    },
    Notification: {
      colorInfo: '#618df6',
      colorWarning: '#d7a647',
    },
    Modal: {
      colorBgMask: 'rgba(0, 0, 0, 0.8)',
      contentBg: '#070D0E',
    },
    Tooltip: {
      colorBgSpotlight: '#2a3138',
    },
    Dropdown: {
      colorBgElevated: '#121416',
    },
  },
})
