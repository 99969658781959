import { CSSProperties } from 'react'

type A8BrandIconProps = {
  color?: string
  className?: string
  style?: CSSProperties
}

export const A8BrandIcon = ({ color, className, style }: A8BrandIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="141"
      height="26"
      viewBox="0 0 141 26"
      fill="none"
      className={className}
      color={color}
      style={style}
    >
      <path
        d="M37.1797 25.1519H37.1251L29.1717 3.5166H24.1504V25.4494H26.9677L26.8571 3.8141H26.9117L34.8611 25.4494H39.8557V3.5166H37.0931L37.1771 25.1519H37.1797Z"
        fill="currentColor"
      />
      <path
        d="M52.8561 5.76831C55.8388 5.76831 57.9615 7.90181 58.6015 11.5171H61.3895C60.6908 6.33073 57.5388 3.21973 52.8521 3.21973C47.1321 3.21973 43.7588 7.39748 43.7588 14.4836C43.7588 21.5698 47.1361 25.7476 52.8521 25.7476C57.5081 25.7476 60.6908 22.5785 61.3895 17.3014H58.6015C57.9615 21.006 55.8681 23.199 52.8561 23.199C49.0348 23.199 46.7468 19.9661 46.7468 14.4836C46.7468 9.00114 49.0348 5.76831 52.8561 5.76831Z"
        fill="currentColor"
      />
      <path
        d="M67.9187 3.5166H64.9907V25.4494H67.9187V3.5166Z"
        fill="currentColor"
      />
      <path
        d="M75.8679 15.401H85.6599V12.9119H75.8679V5.97452H86.6346V3.5166H72.9399V25.4494H86.9426V22.9873H75.8679V15.401Z"
        fill="currentColor"
      />
      <path
        d="M103.649 25.1519H103.594L95.6463 3.5166H90.625V25.4494H93.4423L93.3277 3.8141H93.3863L101.334 25.4494H106.325V3.5166H103.566L103.648 25.1519H103.649Z"
        fill="currentColor"
      />
      <path
        d="M109.34 5.97452H115.589V25.4494H118.517V5.97452H124.767V3.5166H109.34V5.97452Z"
        fill="currentColor"
      />
      <path
        d="M133.492 14.6075C134.356 14.3539 135.17 14.2009 136.419 13.6895C138.031 13.052 140.014 11.6609 140.014 8.93378C140.014 5.63153 137.155 3.22461 133.279 3.22461C129.403 3.22461 126.544 5.63578 126.544 8.93378V9.00036C126.588 12.831 131.156 12.8537 132.948 12.7205C132.08 13.0024 131.139 13.2829 130.171 13.6428C128.472 14.276 125.894 16.0114 125.894 19.274C125.894 23.1741 129.238 25.7496 133.279 25.7496C137.32 25.7496 140.664 23.5056 140.664 19.6055C140.664 14.9008 135.308 14.5764 133.495 14.6104L133.492 14.6061V14.6075ZM131.042 11.6368C131.042 11.6368 131.027 11.6254 131.023 11.6212C130.254 11.0035 129.798 10.077 129.798 9.05703C129.798 7.21253 131.159 5.78594 133.278 5.78594C135.396 5.78594 136.787 7.21253 136.787 9.05703C136.787 9.73703 136.578 10.3774 136.206 10.9129C135.894 11.3619 135.459 11.6821 134.996 11.9173C134.955 11.9371 134.915 11.9598 134.871 11.9796C133.624 12.5859 132.164 12.4953 131.042 11.6354V11.6368ZM133.278 23.1699C130.927 23.1699 129.147 21.3962 129.147 19.0814C129.147 17.5769 129.879 16.3656 131.019 15.6856C131.092 15.6431 131.166 15.6034 131.236 15.5652C132.579 14.8186 134.156 14.8299 135.472 15.6275C135.528 15.6629 135.558 15.6828 135.558 15.6828C136.706 16.3628 137.438 17.5783 137.438 19.0828C137.438 21.4004 135.654 23.1713 133.276 23.1713L133.278 23.1699Z"
        fill="currentColor"
      />
      <path
        d="M21.3599 17.4453H0.00390625V19.9344H21.3599V17.4453Z"
        fill="currentColor"
      />
      <path
        d="M3.616 23.4961L2.924 25.4497H0L2.04933 19.9346L2.62267 20.3256C3.616 21.0013 4.028 22.3231 3.612 23.4918L3.616 23.4961Z"
        fill="currentColor"
      />
      <path
        d="M21.36 25.4493H18.3213L17.6413 23.5651C17.2067 22.361 17.648 21.001 18.6893 20.3408L19.3213 19.9385L21.3587 25.4536V25.4493H21.36Z"
        fill="currentColor"
      />
      <path
        d="M19.3211 19.936H16.3598L15.4731 17.4469L10.6304 3.81433H10.5824L5.75576 17.4455L4.87709 19.9346H2.05176L2.97442 17.4455L8.15042 3.51683H10.5224L9.36509 0.25H12.0544L18.4024 17.4455L19.3224 19.9346L19.3211 19.936Z"
        fill="currentColor"
      />
    </svg>
  )
}
