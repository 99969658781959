export const DoubleDownArrow = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_9780_222695)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.207 5.97788L12 13.7379L4.79297 5.97888L5.52597 5.29888L12 12.2679L18.474 5.29688L19.207 5.97788ZM5.52697 10.7979L12 17.7679L18.474 10.7959L19.207 11.4759L12 19.2399L4.79297 11.4779L5.52697 10.7979Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_9780_222695">
        <rect
          width="15"
          height="15"
          fill="white"
          transform="translate(4.5 4.5)"
        />
      </clipPath>
    </defs>
  </svg>
)
