import React, { Fragment, PropsWithChildren } from 'react'

import { notification } from 'antd'

import {
  ArgsProps,
  NotificationInstance,
} from 'antd/lib/notification/interface'

interface ArgsNotifyProps extends ArgsProps {
  /**
   * It only becomes effective when using `notify.open()`
   */
  type?: 'success' | 'info' | 'error' | 'warning'
  /**
   * The variant to use.
   * @defaultValue 'outline'
   */
  variant?: 'outline' | 'contained'
  /**
   * Case variant is `outline` and not use `notify.open()`:
   * message color with `action` or `type`.
   *
   * Another case: message color is text primary color
   *
   */
  message: React.ReactNode
  /**
   * @text-color default: text primary color
   */
  description?: React.ReactNode
}

type NotifyActionFn = (config: ArgsNotifyProps) => void
type NotifyDestroyFn = (key?: React.Key) => void

interface NotifyInstance extends NotificationInstance {
  success: NotifyActionFn
  error: NotifyActionFn
  warning: NotifyActionFn
  info: NotifyActionFn
  open: NotifyActionFn
  destroy: NotifyDestroyFn
}

const types: (keyof NotifyInstance)[] = [
  'success',
  'error',
  'info',
  'warning',
  'open',
  'destroy',
]

//----------------------------------Notify Provider-----------------------------

export const notify = {} as NotifyInstance

type NotifyProviderProps = {
  prefixCls?: string
} & PropsWithChildren

function NotifyProvider({
  children,
  prefixCls = 'space-3',
}: NotifyProviderProps) {
  const [api, contextHolder] = notification.useNotification({
    maxCount: 6,
    stack: false, //TODO: enable later when update style for stack
  })

  for (const key of types) {
    switch (key) {
      case 'destroy': {
        notify.destroy = (config) => api[key](config)
        break
      }

      default: {
        notify[key] = ({ variant = 'outline', type = 'default', ...rest }) => {
          const state = key !== 'open' ? key : type

          return api[key]({
            ...rest,
            className: `${prefixCls}-notification-notice-${state}-${variant}`,
          })
        }
        break
      }
    }
  }

  return (
    <Fragment>
      {children}
      {contextHolder}
    </Fragment>
  )
}

export default NotifyProvider
