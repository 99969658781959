import { useMemo } from 'react'
import { useRouter } from 'next/router'

import LoginModal from '@/components/login/LoginModal'

import { useLoginModalValue } from '@/components/login'

import { ModalType } from '@/types/common.type'

function LoginProvider() {
  const [isModalOpen, setIsModalOpen] = useLoginModalValue()
  const router = useRouter()

  const modalType = useMemo(() => {
    if (router.isReady && router.asPath.startsWith('/a8-airdrop')) {
      return ModalType.Ancient8
    }

    return ModalType.Space3
  }, [router.isReady, router.asPath])

  return (
    <LoginModal
      isModalOpen={isModalOpen}
      onCloseModal={() => setIsModalOpen(false)}
      modalType={modalType}
    />
  )
}

export default LoginProvider
