import { DISCLAIMER_URL } from '@/constants/loginModal'
import { openInNewTab } from '@/helpers/common'

export default function LoginFooter() {
  return (
    <div className="text-[10px]/none pt-2.5 text-b3 flex flex-col gap-2">
      <div>
        <span className="font-light">Self-custodial log in by Web3Auth</span>
      </div>
      <div
        onClick={() => openInNewTab(DISCLAIMER_URL)}
        className="flex items-center cursor-pointer justify-center gap-2"
      >
        <span className="underline underline-offset-2 policy--text font-light">
          Terms of Service
        </span>
        <div className="h-2 w-[1px] bg-b3"></div>
        <span className="underline underline-offset-2 policy--text font-light">
          Privacy Policy
        </span>
      </div>
      <div className="font-light">Powered by Ancient8 ID</div>
    </div>
  )
}
