import axios from 'axios'

import configs from './configs'

import { getAuthorizationHeader } from '@/utils/accessToken'

import { AccessToken } from './storage'
import { AuthType, AuthEntity } from './authType'

const { a8Api } = configs

export interface AuthChallenge {
  _id: string
  target: string
  message: string
  expiredDate: Date
  isResolved: boolean
  delta: number
}

export type WalletAuthCredential = {
  walletAddress: string
  signedData: string
  authChallengeId: string
}

export type WalletAuth = {
  type: AuthType
  credential: WalletAuthCredential
}

/**
 * Validate user wallet has existed
 * @param walletAddress
 * True if existing, otherwise False
 */
export const validateWallet = async (walletAddress: string) => {
  try {
    await axios.post(
      `${a8Api.user}/validate/wallet-address/${encodeURIComponent(
        walletAddress,
      )}`,
      {},
    )
    return false
  } catch (er) {
    return true
  }
}

/**
 * Request auth challenge before login/logout/connect wallet
 * @param walletAddress
 */
export const challengeWallet = async (
  walletAddress: string,
): Promise<AuthChallenge> => {
  const { data } = await axios.post(
    `${a8Api.auth}/challenge/${walletAddress}`,
    {},
  )
  return data
}

/**
 * Login using credential from wallet signature.
 * @param payload
 */
export const loginWallet = async (
  payload: WalletAuth,
): Promise<AccessToken> => {
  const { data } = await axios.post(`${a8Api.auth}/login-wallet`, payload)
  return data
}

/**
 * Sign up user using credential from wallet signature.
 * @param payload
 */
export const signupWallet = async (
  payload: WalletAuth,
): Promise<AccessToken> => {
  const { data } = await axios.post(`${a8Api.auth}/sign-up`, payload)
  return data
}

/**
 * Connect user wallet using credential from wallet signature.
 * @param payload
 */
export const connectWallet = async (
  payload: WalletAuth,
): Promise<AuthEntity> => {
  const { data } = await axios.post(`${a8Api.auth}/connect-wallet`, payload, {
    headers: getAuthorizationHeader(),
  })
  return data
}
