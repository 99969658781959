import { Fragment, useCallback, useState } from 'react'
import Image from 'next/image'
import { useMap } from 'react-use'
import clsx from 'clsx'
import { useSignMessage, useDisconnect, useNetwork, useAccount } from 'wagmi'

import CloseIcon from './CloseIcon'
import EmailError from './EmailError'
import LoginFooter from './LoginFooter'
import WalletGroup from './WalletGroup'
import LoginLoading from './LoginLoading'
import EmailOTP from './EmailOTP'
import A8LoginLoading from './A8LoginLoading'

import useConnectors from '@/hooks/login/useConnectors'
import { useChainMap } from '@/hooks/useChainMap'
import { useRoninSignMessage } from '@/hooks/adapters/useCustomizedSignMessage'

import { openInNewTab } from '@/helpers/common'
import { isEmailAddress, isEthereumAddress, isRoninAddress } from '@/helpers'

import { useMainStore } from '@/stores/main.store'
import { useCustomerStore } from '@/stores/customer.store'
import { useUserStore } from '@/stores/user.store'
import { IConnector } from '.'

import {
  challengeWallet,
  loginWallet,
  signupWallet,
  validateWallet,
} from '@/services/auth/walletAuth'
import { AuthType } from '@/services/auth/authType'
import configs from '@/services/auth/configs'

import { notify } from '@/providers/notify.provider'

import { oauthUrlMap } from '@/constants/oauth'
import { ISocial, socialWallets } from '@/constants/loginModal'

import HeaderIcon from '@/assets/images/logo/brand.svg'
import A8HeaderIcon from '@/assets/images/logo/a8-logo.svg'

import { ModalType } from '@/types/common.type'

interface ILoginModalContentProps {
  closeModal: () => void
  modalType?: ModalType
}

const { oauth } = configs

function LoginModalContent({
  closeModal,
  modalType = ModalType.Space3,
}: ILoginModalContentProps) {
  const { connector: currentConnector } = useAccount()
  const { roninSignMessage } = useRoninSignMessage()
  const { onSwitchWallet } = useMainStore((state) => state)
  const { disconnect } = useDisconnect()
  const [map, { set, get }] = useMap({
    emailAddress: '',
    isEmailError: false,
    isConnecting: false,
    isSendingOTP: false,
  })

  const { signMessage } = useSignMessage({
    onError: () => {
      if (isConnected) disconnect()
      set('isConnecting', false)
    },
    async onSuccess(data) {
      await getAndSetToken(data)
      closeModal()
    },
  })
  const { chain } = useNetwork()
  const { getChainId } = useChainMap()

  const { connect, wallets, walletAddress, isConnected } = useConnectors({
    async onSuccess(data) {
      const { account, connector } = data
      const connectorId = connector?.id.toLowerCase()
      const address =
        (connectorId === 'roninwallet' ? 'ronin:' : '0x') + account.slice(2)

      await getSignature(address.toLowerCase())

      // TODO: Change it when working on multi-chain context
      // Additional: Not support phantom yet
      if (connectorId && connectorId !== 'phantom')
        onSwitchWallet({
          address: address.toLowerCase(),
          walletType: connectorId,
          chain: getChainId(chain?.id),
        })
    },
    onError() {
      set('isConnecting', false)
    },
  })

  const { login } = useUserStore()
  const { getCustomers } = useCustomerStore()
  const [authChallengeId, setAuthChallengeId] = useState('')

  const getAndSetToken = useCallback(
    async (
      signature: string,
      _authChallengeId?: string,
      _walletAddress?: string,
    ) => {
      const formattedWalletAddress = (
        _walletAddress || walletAddress
      ).toLowerCase()
      const connectorId = currentConnector?.id.toLowerCase()

      const payload = {
        type:
          connectorId === 'roninwallet' ? AuthType.Ronin : AuthType.EVMChain,
        credential: {
          authChallengeId: _authChallengeId || authChallengeId,
          walletAddress: formattedWalletAddress,
          signedData: signature,
        },
      }
      const existed = await validateWallet(formattedWalletAddress)
      const validate = existed ? loginWallet : signupWallet
      const { accessToken } = await validate(payload)

      try {
        const userProfile = await login(accessToken, formattedWalletAddress)
        if (userProfile?._id) getCustomers()
      } catch (error: any) {
        notify.error({
          message: `Login error: ${error?.repsonse?.data?.message}`,
        })
      }
    },
    [authChallengeId, currentConnector?.id, getCustomers, login, walletAddress],
  )

  const getSignature = useCallback(
    async (address: string) => {
      if (isEthereumAddress(address) || isRoninAddress(address)) {
        const { _id, message } = await challengeWallet(address)
        setAuthChallengeId(_id)
        if (currentConnector?.id.toLowerCase() === 'roninwallet') {
          try {
            const signedMessage = await roninSignMessage(message, address)
            await getAndSetToken(signedMessage, _id, address)
            closeModal()
          } catch (err) {
            if (isConnected) disconnect()
            set('isConnecting', false)
          }
          return
        }
        signMessage({ message })
      }
    },
    [
      closeModal,
      currentConnector?.id,
      disconnect,
      getAndSetToken,
      isConnected,
      roninSignMessage,
      set,
      signMessage,
    ],
  )

  // deal with web3 wallet login
  const handleConnect = (connector: IConnector) => {
    set('emailAddress', '')
    if (isConnected) {
      return getSignature(walletAddress.toLowerCase())
    }
    if (connector.isInstalled()) {
      set('isConnecting', true)
      connect({ connector })
    } else {
      openInNewTab(connector.installUrl)
    }
  }

  // deal with social login
  const handleSocial = async (social: ISocial) => {
    const urlMap = oauthUrlMap(
      oauth,
      'login',
      location.pathname + location.search,
    )
    const url = urlMap[social.id as keyof typeof urlMap]
    window.location.href = url
  }

  const handleEmail = async () => {
    const emailAddress = get('emailAddress')
    if (isEmailAddress(emailAddress)) {
      set('isSendingOTP', true)
    } else {
      set('isEmailError', true)
    }
  }

  if (get('isSendingOTP')) {
    return <EmailOTP emailAddress={map.emailAddress} modalType={modalType} />
  }

  if (get('isConnecting') && modalType === ModalType.Space3) {
    return <LoginLoading />
  }

  if (get('isConnecting') && modalType === ModalType.Ancient8) {
    return <A8LoginLoading />
  }

  return (
    <div
      className={clsx(
        'relative rounded-xl flex flex-col gap-4 p-10 text-center login-modal--content',
        modalType === ModalType.Space3 && 'bg-b1',
      )}
    >
      {modalType === ModalType.Space3 && (
        <Fragment>
          <CloseIcon />
          <Image src={HeaderIcon} alt="logo" className="w-full h-[66px]" />
        </Fragment>
      )}

      {modalType === ModalType.Ancient8 && (
        <Image src={A8HeaderIcon} alt="logo" className="w-full h-[66px]" />
      )}

      <div className="text-[20px]/none font-bold text-primary modal-title">
        SIGN IN
      </div>
      <div className="flex flex-col gap-1.5">
        <input
          className={clsx(
            'h-12 w-full bg-transparent px-3 text-sm outline-none transition-colors form-input',
            'rounded-[10px] text-white border-[0.5px] border-solid border-b3 focus:border-primary',
            'placeholder:text-b3 placeholder:text-[14px] placeholder:font-light',
            get('isEmailError') && 'border-danger',
          )}
          onInput={(e: any) =>
            set('emailAddress', e.target.value.toLowerCase())
          }
          onFocus={() => set('isEmailError', false)}
          value={get('emailAddress')}
          type="text"
          placeholder="name@example.com"
        />
        {get('isEmailError') && <EmailError />}
      </div>

      <div
        onClick={() => handleEmail()}
        className="h-12 w-full cursor-pointer rounded-lg text-b2 font-bold shadow-bs1 bg-primary py-3 text-center text-base/[26px] transition-all btn-connect-email"
      >
        Continue with Email
      </div>
      <div className="flex items-center gap-2 p-2">
        <div className="h-[0.5px] flex-1 bg-b3"></div>
        <div className="text-xs font-light text-b3">or Sign in with</div>
        <div className="h-[0.5px] flex-1 bg-b3"></div>
      </div>
      <WalletGroup
        title="Social Account"
        wallets={socialWallets}
        onClick={handleSocial}
      />
      <WalletGroup
        title="Crypto Wallet"
        wallets={wallets}
        onClick={handleConnect}
      />
      <LoginFooter />
    </div>
  )
}

export default LoginModalContent
