/**
 * Constructor
 */

type Config = {
  clientKey: string
  ancient8Api: string
  ancient8ScanApi: string
  ancient8Oauth: string
  missionApi: string
  marketplaceApi: string
  acceler8Api: string
  acceler8TaskApi: string
}

const configs: Config = {
  clientKey: process.env.NEXT_PUBLIC_SP3_CLIENT_KEY as string,
  ancient8Api: process.env.NEXT_PUBLIC_A8_API_URL as string,
  ancient8ScanApi: process.env.NEXT_PUBLIC_A8_SCAN_V2_URL as string,
  ancient8Oauth: process.env.NEXT_PUBLIC_A8_OAUTH_URL as string,
  missionApi: process.env.NEXT_PUBLIC_BOUNTY_SYSTEM_API_URL as string,
  marketplaceApi: process.env.NEXT_PUBLIC_MARKET_PLACE_API_URL as string,
  acceler8Api: process.env.NEXT_PUBLIC_ACCELER8_API_URL as string,
  acceler8TaskApi: process.env.NEXT_PUBLIC_ACCELER8_TASK_API_URL as string,
}

/**
 * Module exports
 */
export default configs
