export const ChevronExpandIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.85713 11.0035C7.10673 11.0035 6.70026 10.0967 7.26306 9.5339L11.3903 5.28141C11.5466 5.0938 11.7968 5 12.0156 5C12.2345 5 12.4534 5.0938 12.6097 5.28141L16.7369 9.5339C17.2997 10.0967 16.8933 11.0035 16.1429 11.0035H7.85713ZM16.1429 14.0047C16.8933 14.0047 17.2997 14.9427 16.7369 15.5055L12.6097 19.758C12.4534 19.9456 12.2345 20.0082 12.0156 20.0082C11.7655 20.0082 11.5466 19.9456 11.3903 19.758L7.26306 15.5055C6.70026 14.9427 7.10673 14.0047 7.85713 14.0047H16.1429Z"
      fill="white"
    />
  </svg>
)
