export const StepArrowIcon = () => (
  <svg
    width="81"
    height="20"
    viewBox="0 0 81 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.90436 16.9416L9.90436 18.9297L11.8925 18.9297L11.8925 16.9517L13.8706 16.9517L13.8706 14.9736L15.8486 14.9736L15.8486 12.9956L17.8267 12.9956L17.8267 11.0385L19.7663 11.0385L19.7663 11.0175L19.8048 11.0175L19.8048 9.02936L17.8166 9.02936L17.8166 9.05037L17.7781 9.05037L17.7781 11.0074L15.8385 11.0074L15.8385 12.9855L13.8605 12.9855L13.8605 14.9635L11.8824 14.9635L11.8824 16.9416L9.90436 16.9416ZM11.8129 1.09775L9.8247 1.09775L9.8247 3.08591L11.8129 3.08591L11.8129 5.07406L13.801 5.07406L13.801 7.06144L15.7892 7.06144L15.7892 5.07329L13.801 5.07329L13.801 3.08591L11.8129 3.08591L11.8129 1.09775ZM17.7773 7.06222L15.7892 7.06222L15.7892 9.05037L17.7773 9.05037L17.7773 7.06222Z"
      fill="#0BCBC1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7559 16.9416L21.7559 18.9297L23.7441 18.9297L23.7441 16.9517L25.7221 16.9517L25.7221 14.9736L27.7002 14.9736L27.7002 12.9956L29.6783 12.9956L29.6783 11.0385L31.6178 11.0385L31.6178 11.0175L31.6563 11.0175L31.6563 9.02936L29.6682 9.02936L29.6682 9.05037L29.6297 9.05037L29.6297 11.0074L27.6901 11.0074L27.6901 12.9855L25.712 12.9855L25.712 14.9635L23.734 14.9635L23.734 16.9416L21.7559 16.9416ZM23.6644 1.09775L21.6763 1.09775L21.6763 3.08591L23.6644 3.08591L23.6644 5.07406L25.6526 5.07406L25.6526 7.06144L27.6407 7.06144L27.6407 5.07329L25.6526 5.07329L25.6526 3.08591L23.6644 3.08591L23.6644 1.09775ZM29.6289 7.06222L27.6407 7.06222L27.6407 9.05037L29.6289 9.05037L29.6289 7.06222Z"
      fill="#0BCBC1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.6055 16.9416L33.6055 18.9297L35.5937 18.9297L35.5937 16.9517L37.5717 16.9517L37.5717 14.9736L39.5498 14.9736L39.5498 12.9956L41.5279 12.9956L41.5279 11.0385L43.4674 11.0385L43.4674 11.0175L43.5059 11.0175L43.5059 9.02936L41.5178 9.02936L41.5178 9.05037L41.4793 9.05037L41.4793 11.0074L39.5397 11.0074L39.5397 12.9855L37.5616 12.9855L37.5616 14.9635L35.5836 14.9635L35.5836 16.9416L33.6055 16.9416ZM35.514 1.09775L33.5259 1.09775L33.5259 3.08591L35.514 3.08591L35.514 5.07406L37.5022 5.07406L37.5022 7.06144L39.4903 7.06144L39.4903 5.07329L37.5022 5.07329L37.5022 3.08591L35.514 3.08591L35.514 1.09775ZM41.4785 7.06222L39.4903 7.06222L39.4903 9.05037L41.4785 9.05037L41.4785 7.06222Z"
      fill="#0BCBC1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.4551 16.9416L45.4551 18.9297L47.4433 18.9297L47.4433 16.9517L49.4214 16.9517L49.4214 14.9736L51.3994 14.9736L51.3994 12.9956L53.3775 12.9956L53.3775 11.0385L55.317 11.0385L55.317 11.0175L55.3555 11.0175L55.3555 9.02936L53.3674 9.02936L53.3674 9.05037L53.3289 9.05037L53.3289 11.0074L51.3893 11.0074L51.3893 12.9855L49.4113 12.9855L49.4113 14.9635L47.4332 14.9635L47.4332 16.9416L45.4551 16.9416ZM47.3636 1.09775L45.3755 1.09775L45.3755 3.08591L47.3636 3.08591L47.3636 5.07406L49.3518 5.07406L49.3518 7.06144L51.34 7.06144L51.34 5.07329L49.3518 5.07329L49.3518 3.08591L47.3636 3.08591L47.3636 1.09775ZM53.3281 7.06222L51.34 7.06222L51.34 9.05037L53.3281 9.05037L53.3281 7.06222Z"
      fill="#0BCBC1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.3067 16.9416L57.3067 18.9297L59.2949 18.9297L59.2949 16.9517L61.2729 16.9517L61.2729 14.9736L63.251 14.9736L63.251 12.9956L65.229 12.9956L65.229 11.0385L67.1686 11.0385L67.1686 11.0175L67.2071 11.0175L67.2071 9.02936L65.2189 9.02936L65.2189 9.05037L65.1804 9.05037L65.1804 11.0074L63.2409 11.0074L63.2409 12.9855L61.2628 12.9855L61.2628 14.9635L59.2848 14.9635L59.2848 16.9416L57.3067 16.9416ZM59.2152 1.09775L57.227 1.09775L57.227 3.08591L59.2152 3.08591L59.2152 5.07406L61.2034 5.07406L61.2034 7.06144L63.1915 7.06144L63.1915 5.07329L61.2034 5.07329L61.2034 3.08591L59.2152 3.08591L59.2152 1.09775ZM65.1797 7.06222L63.1915 7.06222L63.1915 9.05037L65.1797 9.05037L65.1797 7.06222Z"
      fill="#0BCBC1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.1563 16.9416L69.1563 18.9297L71.1445 18.9297L71.1445 16.9517L73.1225 16.9517L73.1225 14.9736L75.1006 14.9736L75.1006 12.9956L77.0786 12.9956L77.0786 11.0385L79.0182 11.0385L79.0182 11.0175L79.0567 11.0175L79.0567 9.02936L77.0685 9.02936L77.0685 9.05037L77.0301 9.05037L77.0301 11.0074L75.0905 11.0074L75.0905 12.9855L73.1124 12.9855L73.1124 14.9635L71.1344 14.9635L71.1344 16.9416L69.1563 16.9416ZM71.0648 1.09775L69.0767 1.09775L69.0767 3.08591L71.0648 3.08591L71.0648 5.07406L73.053 5.07406L73.053 7.06144L75.0411 7.06144L75.0411 5.07329L73.053 5.07329L73.053 3.08591L71.0648 3.08591L71.0648 1.09775ZM77.0293 7.06222L75.0411 7.06222L75.0411 9.05037L77.0293 9.05037L77.0293 7.06222Z"
      fill="#0BCBC1"
    />
  </svg>
)
