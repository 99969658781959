export const AchieveIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
  >
    <g clipPath="url(#clip0_1386_225)">
      <path
        d="M9.39323 10.9977H8.95768L8.62479 11.2769C8.16901 11.6596 7.5919 11.8696 6.99923 11.8696C6.40657 11.8696 5.82868 11.6588 5.37368 11.2769L5.04079 10.9977H4.60523C4.35557 10.9977 4.11679 10.9526 3.88734 10.8849V13.9167C3.88734 14.1391 4.01334 14.3413 4.21323 14.4401C4.41079 14.5373 4.64957 14.5148 4.82612 14.3787L6.99846 12.708L9.17079 14.3787C9.27501 14.4588 9.40023 14.5 9.52623 14.5C9.61412 14.5 9.70279 14.4806 9.78368 14.4401C9.98357 14.3421 10.1096 14.1391 10.1096 13.9167V10.8849C9.88012 10.9526 9.64134 10.9977 9.39168 10.9977H9.39323Z"
        fill="url(#paint0_linear_1386_225)"
      />
      <path
        d="M11.8448 5.58356L11.4512 4.90145C11.4279 4.861 11.4193 4.81356 11.4271 4.77L11.5648 3.99222C11.6791 3.34122 11.312 2.70422 10.6898 2.47711L9.94856 2.208C9.90578 2.19245 9.86923 2.16211 9.84667 2.12167L9.45156 1.43956C9.12101 0.865558 8.42801 0.615113 7.80734 0.840669L7.06612 1.11056C7.02334 1.12611 6.97667 1.12611 6.9339 1.11056L6.19267 0.840669C5.56812 0.613558 4.87823 0.865558 4.54845 1.43878L4.15256 2.12322C4.13078 2.16211 4.09423 2.19245 4.05223 2.208L3.30945 2.47789C2.68801 2.705 2.32089 3.34122 2.43523 3.99378L2.57212 4.76845C2.58067 4.81434 2.57212 4.86178 2.54956 4.90145L2.15523 5.58434C1.82389 6.15678 1.95145 6.88167 2.45934 7.30789L3.06212 7.81423C3.0979 7.84378 3.12123 7.885 3.12978 7.93089L3.26745 8.70711C3.38178 9.35889 3.94567 9.83178 4.60756 9.83178H5.39701C5.4429 9.83178 5.48645 9.84811 5.52145 9.87767L6.12578 10.3848C6.37934 10.5979 6.69045 10.7044 7.00078 10.7044C7.31112 10.7044 7.62223 10.5979 7.87578 10.3856L8.48012 9.87767C8.51512 9.84811 8.55945 9.83256 8.60456 9.83256H9.39401C10.0559 9.83256 10.619 9.35967 10.7341 8.70867L10.8718 7.93089C10.8803 7.88578 10.9037 7.84456 10.9379 7.81578L11.543 7.30867C12.0493 6.88245 12.1769 6.15834 11.8463 5.58589L11.8448 5.58356ZM7.00001 7.28534C6.14056 7.28534 5.44445 6.58845 5.44445 5.72978C5.44445 4.87111 6.14056 4.17422 7.00001 4.17422C7.85945 4.17422 8.55556 4.87111 8.55556 5.72978C8.55556 6.58845 7.85945 7.28534 7.00001 7.28534Z"
        fill="url(#paint1_linear_1386_225)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1386_225"
        x1="3.88734"
        y1="12.6924"
        x2="10.1096"
        y2="12.6924"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ACFFFB" />
        <stop offset="0.465" stopColor="#1FD9D0" />
        <stop offset="1" stopColor="#0C8D86" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1386_225"
        x1="1.97192"
        y1="5.73107"
        x2="12.0291"
        y2="5.73107"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ACFFFB" />
        <stop offset="0.465" stopColor="#1FD9D0" />
        <stop offset="1" stopColor="#0C8D86" />
      </linearGradient>
      <clipPath id="clip0_1386_225">
        <rect
          width="14"
          height="14"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)
