export enum SEOKey {
  TITLE,
  DESC,
  KEYWORDS,
  ROBOTS,
  CANONICAL,
  OG_TITLE,
  OG_DESC,
  OG_IMG,
  OG_IMG_ALT,
  OG_IMG_TYPE,
  OG_SITE_NAME,
  OG_URL,
  OG_TYPE,
  TWITTER_CARD,
  TWITTER_DOMAIN,
  TWITTER_URL,
  TWITTER_TITLE,
  TWITTER_DESC,
  TWITTER_IMG,
}

export const PAGE_TITLE = {
  LANDING: 'Your Daily Gaming Gateway - Space3',
  MISSIONS: 'Missions - Space3',
  REWARDS: 'Rewards Center - Space3',
  GAMES: 'Games - Space3',
  PROFILE: 'My Profile - Space3',
}
