import SolanaLogo from '@/assets/images/logoChain/solana.png'
import BinanceLogo from '@/assets/images/logoChain/binance-outline.png'
import EthereumLogo from '@/assets/images/logoChain/eth.png'
import PolygonLogo from '@/assets/images/logoChain/polygon.png'
import KlaytnLogo from '@/assets/images/logoChain/klaytn.png'
import BORALogo from '@/assets/images/logoChain/bora.png'
import ISKRALogo from '@/assets/images/logoChain/iskra.png'
import ArbitrumLogo from '@/assets/images/logoChain/arbitrum.png'
import AvalancheLogo from '@/assets/images/logoChain/avalanche.png'
import LootchainLogo from '@/assets/images/logoChain/lootchain.png'
import VictionLogo from '@/assets/images/logoChain/viction.png'
import BaseLogo from '@/assets/images/logoChain/base.png'
import A8Logo from '@/assets/images/logoChain/a8.png'
import RoninLogo from '@/assets/images/logoChain/ronin.png'
import OptimismLogo from '@/assets/images/logoChain/optimism.png'
import BitcoinLogo from '@/assets/images/logoChain/bitcoin.png'
import LineaLogo from '@/assets/images/logoChain/linea.png'
import StarknetLogo from '@/assets/images/logoChain/starknet.png'
import ZksyncLogo from '@/assets/images/logoChain/zksync.png'
import MintLogo from '@/assets/images/logoChain/mint.png'
import KromaLogo from '@/assets/images/logoChain/kroma.png'
import EtherlinkLogo from '@/assets/images/logoChain/etherlink.png'
import DefaultLogo from '@/assets/images/logoChain/default.png'

import { IChain } from '@/types/chain.type'
import { ChainID, CommunityChainID } from '.'
import { ChainState } from '@/stores/chain.store'

export const CHAIN_DEFAULT: IChain = {
  chainNumber: 0,
  chainId: ChainID.Default,
  scanner: '',
  cluster: '',
  contracts: {},
  paymentCurrencies: [],
  nativeToken: {
    symbol: '',
    decimal: '',
  },
}

export const CHAIN_STATE_DEFAULT: ChainState = {
  [ChainID.A8]: { ...CHAIN_DEFAULT, chainId: ChainID.A8 },
  [ChainID.Arbitrum]: { ...CHAIN_DEFAULT, chainId: ChainID.Arbitrum },
  [ChainID.BSC]: CHAIN_DEFAULT,
  [ChainID.Ethereum]: { ...CHAIN_DEFAULT, chainId: ChainID.Ethereum },
  [ChainID.Polygon]: { ...CHAIN_DEFAULT, chainId: ChainID.Polygon },
  [ChainID.Optimism]: { ...CHAIN_DEFAULT, chainId: ChainID.Optimism },
  [ChainID.Base]: { ...CHAIN_DEFAULT, chainId: ChainID.Base },
  [ChainID.Ronin]: { ...CHAIN_DEFAULT, chainId: ChainID.Ronin },
  [ChainID.Solana]: { ...CHAIN_DEFAULT, chainId: ChainID.Solana },
  [ChainID.Kroma]: { ...CHAIN_DEFAULT, chainId: ChainID.Kroma },
  [ChainID.Mint]: { ...CHAIN_DEFAULT, chainId: ChainID.Mint },
  [ChainID.Etherlink]: { ...CHAIN_DEFAULT, chainId: ChainID.Etherlink },
  [ChainID.Default]: { ...CHAIN_DEFAULT },
}

export const ChainLogoMap: Record<CommunityChainID | ChainID, string> = {
  [CommunityChainID.BSC]: BinanceLogo.src,
  [CommunityChainID.Solana]: SolanaLogo.src,
  [CommunityChainID.Ethereum]: EthereumLogo.src,
  [CommunityChainID.Polygon]: PolygonLogo.src,
  [CommunityChainID.Klaytn]: KlaytnLogo.src,
  [CommunityChainID.BORA]: BORALogo.src,
  [CommunityChainID.ISKRA]: ISKRALogo.src,
  [CommunityChainID.Arbitrum]: ArbitrumLogo.src,
  [CommunityChainID.Avalanche]: AvalancheLogo.src,
  [CommunityChainID.Lootchain]: LootchainLogo.src,
  [CommunityChainID.Viction]: VictionLogo.src,
  [CommunityChainID.Base]: BaseLogo.src,
  [CommunityChainID.Optimism]: OptimismLogo.src,
  [CommunityChainID.Bitcoin]: BitcoinLogo.src,
  [CommunityChainID.Linea]: LineaLogo.src,
  [CommunityChainID.Starknet]: StarknetLogo.src,
  [CommunityChainID.Zksync]: ZksyncLogo.src,
  [CommunityChainID.Mint]: MintLogo.src,
  [CommunityChainID.Kroma]: KromaLogo.src,
  [CommunityChainID.Etherlink]: EtherlinkLogo.src,
  [ChainID.A8]: A8Logo.src,
  [ChainID.Ronin]: RoninLogo.src,
  [ChainID.Default]: DefaultLogo.src,
}
