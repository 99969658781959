export const SingleArrowRightIcon = (props: any) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clipPath="url(#clip0_14971_18557)">
        <path
          d="M4.9165 12.4166L10.7498 6.99992L4.9165 1.58325"
          stroke="currentColor"
          strokeWidth="1.2"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_14971_18557">
          <rect
            width="12.5"
            height="12.5"
            fill="white"
            transform="translate(0.75 0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
