import clsx from 'clsx'
import Image from 'next/image'

import { IConnector } from '.'
import { ISocial } from '@/constants/loginModal'

interface IProps<T> {
  onClick: (wallet: T) => void
  wallets: T[]
  title: string
  className?: string
}

const WalletGroup = <T extends IConnector | ISocial>({
  wallets,
  className,
  title,
  onClick,
}: IProps<T>) => {
  return (
    <div className={clsx('flex flex-col gap-2 mx-2.5', className)}>
      <div className="text-center text-xs font-light text-b4 p-2 wallet-group--title">
        {title}
      </div>
      <div className="flex h-10 justify-between wallet-group--icon">
        {wallets.map((wallet) => (
          <div
            key={wallet.id}
            onClick={() => onClick(wallet)}
            className={clsx(
              'wallet-item cursor-pointer rounded-full flex items-center bg-[#121719] justify-center h-10 w-10',
              'hover:border-primary hover:shadow-bs1 border border-solid border-transparent',
            )}
          >
            <Image
              alt="logo-wallet"
              src={wallet.icon}
              className="wallet-item--icon h-6 w-6"
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default WalletGroup
