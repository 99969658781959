export function EndedIcon() {
  return (
    <svg
      width="187"
      height="55"
      viewBox="0 0 187 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0H93.5H149.25H159.5L187 27.5V55H0V0Z"
        fill="#5B0101"
        fillOpacity="0.65"
      />
      <path
        d="M132.166 17.0244C133.62 17.0198 134.866 17.2848 135.903 17.8193C136.96 18.3339 137.859 19.0979 138.599 20.1113C138.919 20.5684 139.169 21.0556 139.35 21.5728C139.551 22.0701 139.692 22.6273 139.774 23.2445C139.855 23.8417 139.907 24.5088 139.929 25.2456C139.952 25.9626 139.964 26.7592 139.967 27.6355C139.969 28.5118 139.962 29.3185 139.944 30.0555C139.927 30.7725 139.879 31.4399 139.801 32.0575C139.723 32.6553 139.586 33.2134 139.388 33.7318C139.211 34.2303 138.963 34.7091 138.646 35.1681C137.912 36.1862 137.018 36.9657 135.964 37.5068C134.93 38.0278 133.686 38.2906 132.232 38.2952L124.555 38.3191L124.488 17.0483L132.166 17.0244ZM128.652 20.7398L128.696 34.6017L131.862 34.5918C133.237 34.5875 134.271 34.1461 134.965 33.2676C135.164 33.028 135.312 32.7686 135.411 32.4895C135.53 32.1904 135.618 31.8216 135.676 31.3833C135.735 30.9449 135.773 30.427 135.791 29.8294C135.809 29.212 135.817 28.485 135.814 27.6485C135.812 26.812 135.799 26.095 135.778 25.4976C135.756 24.8802 135.714 24.3625 135.653 23.9445C135.592 23.5065 135.501 23.1383 135.381 22.84C135.28 22.5415 135.13 22.2731 134.93 22.0347C134.23 21.1606 133.193 20.7257 131.819 20.73L128.652 20.7398Z"
        fill="#DB4646"
      />
      <path
        d="M120.585 20.7652L110.727 20.796L110.742 25.7851L119.167 25.7588L119.178 29.4632L110.754 29.4895L110.77 34.6579L120.629 34.6271L120.64 38.3315L106.629 38.3753L106.562 17.1045L120.574 17.0608L120.585 20.7652Z"
        fill="#DB4646"
      />
      <path
        d="M94.709 17.1411C96.1629 17.1365 97.4086 17.4015 98.4459 17.936C99.5031 18.4506 100.402 19.2146 101.142 20.228C101.462 20.6851 101.712 21.1723 101.893 21.6895C102.094 22.1868 102.235 22.744 102.317 23.3612C102.398 23.9584 102.45 24.6255 102.472 25.3623C102.494 26.0793 102.507 26.8759 102.51 27.7522C102.512 28.6285 102.505 29.4352 102.487 30.1722C102.47 30.8892 102.422 31.5566 102.344 32.1742C102.266 32.772 102.129 33.3301 101.931 33.8485C101.754 34.347 101.506 34.8258 101.189 35.2848C100.455 36.3029 99.5613 37.0824 98.5074 37.6235C97.4733 38.1445 96.2293 38.4073 94.7754 38.4119L87.0976 38.4358L87.0312 17.165L94.709 17.1411ZM91.1954 20.8565L91.2387 34.7184L94.4054 34.7085C95.7796 34.7042 96.8139 34.2628 97.5083 33.3843C97.7067 33.1447 97.8552 32.8853 97.9539 32.6062C98.0725 32.3071 98.161 31.9383 98.2194 31.5C98.2777 31.0616 98.316 30.5437 98.334 29.9461C98.352 29.3287 98.3597 28.6017 98.3571 27.7652C98.3545 26.9287 98.3423 26.2117 98.3205 25.6143C98.2987 24.9969 98.2572 24.4792 98.1961 24.0612C98.135 23.6232 98.0443 23.255 97.9238 22.9567C97.8233 22.6582 97.6731 22.3898 97.4732 22.1514C96.7734 21.2773 95.7363 20.8424 94.3621 20.8467L91.1954 20.8565Z"
        fill="#DB4646"
      />
      <path
        d="M82.2778 38.4509L78.6032 38.4624L70.1081 25.4933L70.1487 38.4888L65.9961 38.5017L65.9297 17.231L69.6342 17.2194L78.0994 30.1886L78.0588 17.1931L82.2114 17.1801L82.2778 38.4509Z"
        fill="#DB4646"
      />
      <path
        d="M62.0228 20.9478L52.1641 20.9786L52.1797 25.9677L60.6044 25.9414L60.616 29.6458L52.1913 29.6721L52.2074 34.8405L62.0661 34.8097L62.0776 38.5142L48.0664 38.5579L48 17.2871L62.0112 17.2434L62.0228 20.9478Z"
        fill="#DB4646"
      />
    </svg>
  )
}
