import { CSSProperties } from 'react'
import { createGlobalState } from 'react-use'
import { Connector } from 'wagmi'

import { Button } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'

export type LogInProps = {
  style?: CSSProperties
  block?: boolean
  size?: SizeType
  wrapperCls?: string
}

export type IConnector = Connector<any, any> & Record<string, any>
export const useLoginModalValue = createGlobalState(false)
export const useOpenDisclaimer = createGlobalState(false)
const LogIn = ({
  style,
  block = false,
  size = 'middle',
  wrapperCls = '',
}: LogInProps) => {
  const [, setIsModalOpen] = useLoginModalValue()
  const [, setIsOpenDisclaimer] = useOpenDisclaimer()

  const showModal = () => {
    const disclaimerAcception = localStorage.getItem('disclaimer:acception')
    if (disclaimerAcception) {
      return setIsModalOpen(true)
    }
    setIsOpenDisclaimer(true)
  }

  return (
    <>
      <Button
        size={size}
        block={block}
        type="primary"
        className={`clip-path font-space ${wrapperCls}`}
        style={{ ...style, fontWeight: 700 }}
        onClick={showModal}
      >
        Log in
      </Button>
    </>
  )
}

export default LogIn
