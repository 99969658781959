import React, { ReactNode } from 'react'
import Head from 'next/head'

import { buildUrlFromPath } from '@/utils/urlUtils'

import { SEOKey } from '@/constants/seo'

export type SEOData = {
  title?: string
  desc?: string
  img?: string
  imgAlt?: string
  imgType?: string
  siteName?: string
  url?: string
  twitterCard?: string
  twitterDomain?: string
  canonical?: string
  children?: ReactNode
}

//TODO: implement later
// type ImageSEO = {
//     url: string,
//     alt:
// }

const SEO = ({
  title,
  desc = "Igniting the world's leading Game Publisher, your gateway to the galaxy of millions Exceptional Web3 Games.",
  img = 'https://space3.gg/space3.png',
  imgAlt = 'Space3 cover',
  imgType = 'image/png',
  siteName = 'Space3',
  url = 'https://space3.gg',
  twitterCard = 'summary_large_image',
  twitterDomain = 'space3.gg',
  canonical,
  children,
}: SEOData) => {
  if (!title) title = 'Space3 - Your Daily Gaming Gateway'

  const imgUrl = buildUrlFromPath(img)

  return (
    <Head>
      <title>{title}</title>
      <meta property="og:image" content={imgUrl} key={SEOKey.OG_IMG} />
      <meta name="twitter:image" content={imgUrl} key={SEOKey.TWITTER_IMG} />
      <meta property="title" content={title} key={SEOKey.TITLE} />
      <meta property="description" content={desc} key={SEOKey.DESC} />
      <meta property="og:title" content={title} key={SEOKey.OG_TITLE} />
      <meta property="og:description" content={desc} key={SEOKey.OG_DESC} />

      <meta property="og:image:alt" content={imgAlt} key={SEOKey.OG_IMG_ALT} />
      <meta
        property="og:image:type"
        content={imgType}
        key={SEOKey.OG_IMG_TYPE}
      />
      <meta
        property="og:site_name"
        content={siteName}
        key={SEOKey.OG_SITE_NAME}
      />
      <meta property="og:url" content={url} key={SEOKey.OG_URL} />
      <meta property="og:type" content="website" key={SEOKey.OG_TYPE} />

      <meta
        name="twitter:card"
        content={twitterCard}
        key={SEOKey.TWITTER_CARD}
      />
      <meta
        name="twitter:domain"
        content={twitterDomain}
        key={SEOKey.TWITTER_DOMAIN}
      />
      <meta name="twitter:url" content={url} key={SEOKey.TWITTER_URL} />
      <meta name="twitter:title" content={title} key={SEOKey.TWITTER_TITLE} />
      <meta
        name="twitter:description"
        content={desc}
        key={SEOKey.TWITTER_DESC}
      />

      {canonical && (
        <link rel="canonical" href={canonical} key={SEOKey.CANONICAL} />
      )}

      {children}
    </Head>
  )
}

export default SEO
