export const NumberTwoV2Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={139}
      height={132}
      fill="none"
    >
      <path
        stroke="url(#a)"
        strokeOpacity={0.1}
        strokeWidth={3}
        d="m2.52 129.752-.293 1.748h120.211l.215-1.245 4.287-24.892.302-1.754H53.127c.428-.286.867-.571 1.317-.857a108.438 108.438 0 0 1 6.501-3.574c4.483-2.28 9.386-4.503 14.711-6.67l.008-.003.008-.003a391.92 391.92 0 0 0 16.67-7.476 154.895 154.895 0 0 0 16.581-8.983c5.433-3.316 10.276-6.948 14.523-10.897l.009-.008.008-.008c4.288-4.112 7.669-8.735 10.132-13.866 2.611-5.19 3.905-10.931 3.905-17.195 0-7.24-2.259-13.369-6.826-18.276-4.517-4.974-10.795-8.546-18.728-10.782C104.035 2.661 94.779 1.5 84.2 1.5c-8.21 0-16.244.921-24.099 2.765l-.005.001c-7.768 1.852-14.828 4.759-21.168 8.727l-.011.007c-6.243 3.984-11.283 8.918-15.098 14.802-3.861 5.954-5.89 12.951-6.136 20.937l-.047 1.546h30.701l.194-1.275.71-4.695c.664-3.468 2.202-6.54 4.636-9.241 2.545-2.814 6.079-5.118 10.674-6.871 4.546-1.735 10.13-2.627 16.791-2.627 8.375 0 14.222 1.211 17.79 3.41l.022.014.024.014c3.653 2.076 5.314 4.826 5.314 8.325 0 3.645-1.511 7.032-4.731 10.194-3.217 3.156-7.57 6.353-13.092 9.58-5.55 3.124-11.76 6.252-18.631 9.385l-.009.004a539.683 539.683 0 0 0-20.773 10.2c-6.97 3.546-13.524 7.521-19.66 11.927-6.177 4.434-11.404 9.376-15.668 14.829-4.326 5.531-7.047 11.824-8.158 18.851l-.001.008-1.248 7.435Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1={2.878}
          x2={159.224}
          y1={148.172}
          y2={12.91}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#797979" />
          <stop offset={0.49} stopColor="#fff" />
          <stop offset={1} stopColor="#696969" />
        </linearGradient>
      </defs>
    </svg>
  )
}
