import { Chain } from '@wagmi/core'

export const mint = {
  id: 185,
  name: 'Mint Mainnet',
  network: 'mint',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.mintchain.io'] },
    default: { http: ['https://rpc.mintchain.io'] },
  },
  blockExplorers: {
    etherscan: {
      name: 'MintScan',
      url: 'https://mintscan.org',
    },
    default: {
      name: 'MintScan',
      url: 'https://explorer.mintchain.io',
    },
  },
  contracts: {},
} as const satisfies Chain
