import { ChainID } from '@/constants'
import storage from './storage'

export enum EVMWalletType {
  Metamask = 'metamask',
  Coin98 = 'coin98',
  Coinbase = 'coinbase',
  Torus = 'torus',
}

export const windowPhantom = typeof window !== 'undefined' && window.phantom
export const windowSpace3 = typeof window !== 'undefined' && window
export const windowEthereum = typeof window !== 'undefined' && window.ethereum
export const windowC98 = typeof window !== 'undefined' && window.coin98
export const windowCoinbase =
  typeof window !== 'undefined' && window.coinbaseWalletExtension

/**
 * Chain network
 */
export const CHAIN_KEY = 'chain:network'

const getChain = () => {
  const chainKey = storage.get(CHAIN_KEY)
  return chainKey || ChainID.BSC
}

export const chain: ChainID = getChain()

export const onSwitchChain = (value: ChainID) => {
  storage.set(CHAIN_KEY, value)
  return getChain()
}

/**
 * Wallet type
 */
export const WALLET_TYPE_KEY = 'wallet:type'

export const getWalletType = () => {
  switch (storage.get(WALLET_TYPE_KEY)) {
    case EVMWalletType.Metamask:
      return EVMWalletType.Metamask
    case EVMWalletType.Coin98:
      return EVMWalletType.Coin98
    case EVMWalletType.Coinbase:
      return EVMWalletType.Coinbase
    default:
      return EVMWalletType.Torus
  }
}

export const setWalletType = (value: any) => {
  storage.set(WALLET_TYPE_KEY, value)
  return getWalletType()
}

export const removeStorage = (keys: string[]) => {
  keys.map((key) => storage.clear(key))
}
