import { useCallback } from 'react'
import { hexlify } from 'ethers'

export const useRoninSignMessage = () => {
  const roninSignMessage = useCallback(
    async (message: string, address: string) => {
      const provider = window.ronin.provider
      const signedMessage = await provider.request({
        method: 'personal_sign',
        params: [message, address.replace(/^ronin:/, '0x').toLowerCase()],
      })

      return hexlify(signedMessage || '')
    },
    [],
  )

  return { roninSignMessage }
}
