export const StraightMarkerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="22"
      viewBox="0 0 8 22"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0H0V4V18V22H2V16.25L7.13991 11.7526C7.59524 11.3542 7.59524 10.6458 7.13991 10.2474L2 5.75V0Z"
        fill="#2EF2FF"
      />
    </svg>
  )
}
