import React from 'react'

export const LeaderboardPointIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99992 13.6667C10.6818 13.6667 13.6666 10.6819 13.6666 7.00004C13.6666 3.31814 10.6818 0.333374 6.99992 0.333374C3.31802 0.333374 0.333252 3.31814 0.333252 7.00004C0.333252 10.6819 3.31802 13.6667 6.99992 13.6667ZM8.10569 5.24057L7.26894 3.54514C7.1589 3.32216 6.84094 3.32216 6.7309 3.54514L5.89415 5.24057C5.85045 5.32912 5.76598 5.39049 5.66827 5.40469L3.79724 5.67656C3.55118 5.71232 3.45292 6.01471 3.63098 6.18827L4.98486 7.50798C5.05557 7.57691 5.08784 7.67621 5.07114 7.77352L4.75153 9.63699C4.7095 9.88206 4.96673 10.069 5.18682 9.95324L6.86032 9.07343C6.94771 9.02749 7.05212 9.02749 7.13952 9.07343L8.81302 9.95324C9.03311 10.0689 9.29034 9.88206 9.2483 9.63699L8.92869 7.77352C8.912 7.67621 8.94427 7.57691 9.01497 7.50798L10.3689 6.18827C10.5469 6.01471 10.4487 5.71232 10.2026 5.67656L8.33157 5.40469C8.23386 5.39049 8.14939 5.32912 8.10569 5.24057Z"
        fill="#0FDBD1"
      />
    </svg>
  )
}
