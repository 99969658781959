import Image from 'next/image'

import LoadingImage from './loading-gradient-circle.png'

const LoadingIcon = (props: Record<string, any>) => (
  <Image
    {...props}
    src={LoadingImage.src}
    alt="loading-icon"
    width={100}
    height={100}
  />
)

export default LoadingIcon
