export const CircleQuestionIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0ZM8 14.5C11.5625 14.5 14.5 11.5938 14.5 8C14.5 4.4375 11.5625 1.5 8 1.5C4.40625 1.5 1.5 4.4375 1.5 8C1.5 11.5938 4.40625 14.5 8 14.5ZM8 10.5C8.53125 10.5 9 10.9375 9 11.5C9 12.0625 8.53125 12.5 8 12.5C7.40625 12.5 7 12.0625 7 11.5C7 10.9375 7.4375 10.5 8 10.5ZM9.03125 4C10.2812 4 11.25 4.96875 11.2188 6.1875C11.2188 6.9375 10.8125 7.65625 10.1562 8.0625L8.75 8.9375V9C8.75 9.40625 8.40625 9.75 8 9.75C7.59375 9.75 7.25 9.40625 7.25 9V8.5C7.25 8.25 7.375 8 7.625 7.84375L9.40625 6.78125C9.625 6.65625 9.75 6.4375 9.75 6.1875C9.75 5.8125 9.40625 5.5 9 5.5H7.40625C7.03125 5.5 6.75 5.8125 6.75 6.1875C6.75 6.59375 6.40625 6.9375 6 6.9375C5.59375 6.9375 5.25 6.59375 5.25 6.1875C5.25 4.96875 6.21875 4 7.4375 4H9.03125Z"
        fill="#0FDBD1"
      />
    </svg>
  )
}
