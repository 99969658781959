export const ShareIcon = ({ color = '#0FDBD1' }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M17.5 15C16.6768 15 15.9485 15.3998 15.4933 16.0138L8.90952 12.6587C8.96677 12.4485 9.00002 12.2285 9.00002 12C9.00002 11.714 8.94977 11.4405 8.86127 11.1848L15.4825 7.97202C15.937 8.594 16.6703 9 17.5 9C18.8807 9 20 7.88048 20 6.5C20 5.11925 18.8807 4 17.5 4C16.1193 4 15 5.11927 15 6.5C15 6.69423 15.0245 6.88225 15.0662 7.06375L8.35 10.3228C7.89248 9.8185 7.23448 9.50002 6.5 9.50002C5.11923 9.5 4 10.6193 4 12C4 13.3805 5.11923 14.5 6.5 14.5C7.29523 14.5 8.00198 14.1278 8.45973 13.549L15.0707 16.9182C15.026 17.1053 15 17.2992 15 17.5C15 18.8807 16.1193 20 17.5 20C18.8807 20 20 18.8807 20 17.5C20 16.1193 18.8807 15 17.5 15ZM17.5 5.00002C18.3283 5.00002 19 5.67177 19 6.50002C19 7.32827 18.3283 8.00002 17.5 8.00002C16.6718 8.00002 16 7.32827 16 6.50002C16 5.67177 16.6718 5.00002 17.5 5.00002ZM6.50003 13.5C5.67153 13.5 5.00003 12.8283 5.00003 12C5.00003 11.1718 5.67153 10.5 6.50003 10.5C7.32828 10.5 8.00003 11.1718 8.00003 12C8.00003 12.8283 7.32828 13.5 6.50003 13.5ZM17.5 19C16.6718 19 16 18.3283 16 17.5C16 16.6718 16.6718 16 17.5 16C18.3283 16 19 16.6718 19 17.5C19 18.3283 18.3283 19 17.5 19Z"
      fill={color || 'currentColor'}
    />
  </svg>
)
