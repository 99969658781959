export const FilterIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8165 4.875C15.6143 4.875 16.0343 5.79883 15.5303 6.38672L11.0792 11.6777V19.9922C11.0792 20.6221 10.5753 21 10.0294 21C9.8614 21 9.65144 20.958 9.44148 20.832L6.1241 18.4805C5.83015 18.3125 5.70417 17.9766 5.70417 17.6406V11.6777L1.21101 6.38672C0.707104 5.79883 1.12703 4.875 1.92488 4.875H14.8165ZM10.0294 10.8379L13.9346 6.21875H2.80671L7.04792 11.2158V17.4727L9.73542 19.3623V11.1738L10.0294 10.8379ZM19.1417 6.89062C18.7637 6.89062 18.4698 6.5127 18.4698 6.21875C18.4698 5.88281 18.7637 5.54688 19.1417 5.54688H21.8292C22.1651 5.54688 22.501 5.9668 22.501 6.26074C22.501 6.59668 22.1651 6.89062 21.8292 6.89062H19.1417ZM21.8292 12.2656C22.1651 12.2656 22.501 12.6016 22.501 12.9375C22.501 13.3154 22.1651 13.6094 21.8292 13.6094H15.1104C14.7325 13.6094 14.4385 13.3154 14.4385 12.9375C14.4385 12.6016 14.7325 12.2656 15.1104 12.2656H21.8292ZM21.8292 18.9844C22.1651 18.9844 22.501 19.3203 22.501 19.6562C22.501 20.0342 22.1651 20.3281 21.8292 20.3281H15.1104C14.7325 20.3281 14.4385 20.0342 14.4385 19.6562C14.4385 19.3203 14.7325 18.9844 15.1104 18.9844H21.8292Z"
      fill="white"
    />
  </svg>
)
