export const CupIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="c"
    style={{
      fill: 'var(--gold, linear-gradient(45deg, #907134 0%, #FFC75C 48.96%, #906C25 100%))',
    }}
  >
    <path
      fill="#29333D"
      d="M4.859 17.333c0-.681-.044-1.352.011-2.013.06-.704.71-1.258 1.414-1.301.085-.006.14-.02.167-.113.227-.801.457-1.602.686-2.404.004-.014 0-.031 0-.058-.246-.044-.49-.08-.733-.131-1.392-.295-2.588-.943-3.555-1.995-.791-.862-1.318-1.875-1.67-2.984a12.244 12.244 0 0 1-.495-2.629c-.036-.414-.03-.814.164-1.19.356-.685.937-.995 1.696-.997H4V.667h10.005v.85h.37c.424.004.848-.01 1.27.015 1.025.063 1.747.88 1.686 1.912a11.73 11.73 0 0 1-.486 2.796c-.398 1.284-1.017 2.438-1.996 3.378-.962.925-2.112 1.479-3.416 1.727-.186.035-.373.062-.58.097l.257.89c.145.51.293 1.019.433 1.53.03.112.082.151.2.158.746.041 1.405.715 1.423 1.463.014.592.005 1.184.006 1.776 0 .021-.005.042-.01.073H4.86v.001Zm-.78-14.138H2.481c-.123 0-.16.064-.152.176.071.948.222 1.881.549 2.779.386 1.06.965 1.985 1.885 2.668.294.219.608.405.97.55-1.153-1.908-1.45-4.02-1.656-6.172v-.001Zm9.846.006c-.204 2.144-.504 4.255-1.65 6.156.037-.013.052-.017.066-.023l.133-.06c.94-.454 1.643-1.158 2.153-2.058.679-1.196.937-2.506 1.041-3.858.01-.132-.062-.167-.18-.167-.488.003-.977.002-1.465.002-.031 0-.062.005-.098.008Z"
    />
  </svg>
)
