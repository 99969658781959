import { CustomerData } from '@/types/marketplace'
import { marketplaceApi } from '@/services/base-axios/marketplaceApi'

const CONTROLLER = 'customer'

class Customer {
  async getCustomers() {
    return marketplaceApi.get(`${CONTROLLER}`).then((data: any) => data.data)
  }

  async updateCustomer(customer: CustomerData) {
    return marketplaceApi
      .post(`${CONTROLLER}`, customer)
      .then((data: any) => data.data)
  }
}

export const customerService = new Customer()
